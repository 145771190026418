/* child css classes didnt have any effect on child components
so used mx-0 and px-0 to reduce marging and padding
on respective components */

.admin_management .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.admin_management .row .col-lg-2,
.admin_management .row .col-lg-10 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
}

#container {
    height: 400px;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
@media (max-width: 1200px){
    .managenet_card_media{
      padding: 5px;
    }
}