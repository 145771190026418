.admin_table_avatar {
    height: 50px !important;
    width: 50px !important;
    padding: 0px !important;
    border: none !important;
    box-sizing: content-box !important;
    border-radius: 50% !important;
  }
  
  .rupees_logo_sizing{
    height: 13px;
    margin-right: 3px;
  }