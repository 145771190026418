.admin_management .row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  
  .admin_management .row .col-lg-2,
  .admin_management .row .col-lg-10 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .form-control:focus {
    box-shadow: none !important;
    border-color: #CDD0E34B !important;
  }
  
  .management_header_logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #fff;
  }
  .management_header_logo .management_info_img img {
    margin-left: 10px;
    height: 32px;
    width: 32px;
    box-shadow: 0px 5px 15px #0000001a;
    border-radius: 50%;
  }
  
  .management_sidebar .list_group {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .management_sidebar .list_group_item {
    border: none !important;
    padding: 8px 0px;
    font: 500 14px Spartan;
    color: #2D2424;
    margin-top: 15px;
  }
  .management_sidebar .list_group_item.active {
    background-color: transparent;
  }
  .management_sidebar .list_group_item.active img {
    box-sizing: content-box;
    padding: 10px;
    background-color: #f6efe7;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .management_sidebar .list_group_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .list_group_item img {
    height: 16px;
    width: 16px;
    margin: 0px 25px;
  }
  .management_sidebar .list_group_sub_item {
    padding-left: 90px !important;
    border: none !important;
    padding-top: 8px;
    padding-bottom: 8px;
    font: 500 14px Spartan;
  }
  .management_sidebar .list_group_sub_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .admin_sidebar_footer {
    margin: 0px 25px;
  }
  .management_sidebar .admin_sidebar_footer h4 {
    font: 700 12px/30px Spartan;
    color: #3F4544;
    margin-bottom: 0px !important;
  }
  .management_sidebar .admin_sidebar_footer span {
    font: 500 8px/14px Spartan;
    color: #3F4544;
    display: block;
  }
  
  .management_contents {
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-top-left-radius: 40px;
    padding: 30px;
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
  .management_contents .management_import_export {
    text-align: right;
    margin-bottom: 5px;
  }
  .management_contents .management_import_export img {
    height: 15px;
    width: 16px;
    margin-right: 15px;
  }
  .management_contents .management_import_export span {
    font: 600 10px/20px Spartan;
    color: #2D2424;
  }
  .management_contents .management_import_export span img {
    height: 10px;
    width: 10px;
    vertical-align: baseline;
  }
  .management_contents .management_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #E8E8E8;
  }
  .management_contents .management_header h1 {
    font: 600 24px/33px Spartan;
    color: #3F4544;
  }
  .management_contents .management_header .form_control {
    border: 1px solid #CDD0E34B;
    border-radius: 46px;
    display: inline;
    width: auto;
    font-weight: 600 !important;
    font-size: 12px;
    color: #2D2424;
    padding: 5px 15px;
    height: auto;
  }
  .management_contents .management_contents_search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .management_contents .management_contents_search .management_contents_search_bar {
    max-width: 550px;
    width: 100%;
    position: relative;
  }
  .management_contents .management_contents_search .management_contents_search_bar .form_control {
    border: 1px solid #CDD0E34B;
    border-radius: 46px;
    padding-left: 35px;
    font: 500 10px/10px Spartan;
    letter-spacing: 0px;
    color: #6D6D6D;
  }
  .management_contents .management_contents_search .management_contents_search_bar img {
    position: absolute;
    top: 10px;
    left: 12px;
    z-index: 9999;
  }
  .management_contents .management_contents_search .management_contents_upload span {
    display: inline-block;
    font: 600 12px Spartan;
    color: #9A8776;
    border: 1px solid #9A8776;
    border-radius: 46px;
    padding: 10px 20px;
    margin-left: 15px;
  }
  .management_contents .management_contents_search .management_contents_upload span img {
    vertical-align: bottom !important;
    margin-right: 5px;
  }
  .management_contents .management_contents_search .management_contents_upload span:last-child {
    background-color: #9A8776;
    color: #fff;
    margin-left: 25px !important;
  }
  .management_contents .management_table table {
    border-collapse: separate;
    border-spacing: 0px 15px;
  }
  .management_contents .management_table table th {
    font: 400 12px/20px Spartan;
    color: #2D2424;
    white-space: nowrap;
  }
  .management_contents .management_table table tbody tr {
    background-color: #fff;
  }
  .management_contents .management_table table tbody td {
    font: 600 10px/20px Spartan;
    color: #2D2424;
    vertical-align: middle !important;
    white-space: nowrap;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .management_contents .management_table table tbody td .admin_table_img {
    height: 50px;
    width: 50px;
    padding: 6px;
    box-sizing: content-box;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
  }
  .management_contents .management_table table tbody td .admin_table_img.admin_table_avatar {
    height: 50px;
    width: 50px;
    padding: 0px;
    border: none;
    box-sizing: content-box;
    border-radius: 50%;
  }
  .management_contents .management_table table tbody td .admin_table_edit_img {
    height: 22px;
    width: 22px;
  }
  .management_contents .management_table table tbody td:first-child {
    border-radius: 15px 0 0 15px;
    padding-left: 30px !important;
  }
  .management_contents .management_table table tbody td:last-child {
    border-radius: 0 15px 15px 0;
  }
  .management_contents .management_table table tbody td .status_active {
    font: normal normal medium 11px/14px Spartan;
    color: #60AE52;
    background-color: #D4F2CF;
    padding: 7px 14px;
    border-radius: 20px;
  }
  .management_contents .table_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .management_contents .table_footer .table_pages .form_control {
    width: auto !important;
    display: inline-block;
    border: 1px solid #CBCBCB !important;
    border-radius: 6px !important;
    padding: 5px 10px !important;
    background-color: transparent !important;
    line-height: 0 !important;
    height: auto !important;
  }
  .management_contents .table_footer .table_pages span {
    font: 500 12px/20px Spartan;
    color: #2D2424;
    margin-left: 15px;
  }
  .management_contents .table_footer .pagination .page-item .page-link {
    font: 500 12px/20px Spartan !important;
    padding: 5px 10px !important;
    color: #2E2E2E !important;
    background-color: transparent !important;
    border: none !important;
    margin: 5px !important;
  }
  .management_contents .table_footer .pagination .page-item .page-link:hover, .management_contents .table_footer .pagination .page-item .page-link:active {
    background-color: #9A8776 !important;
    color: #fff !important;
    border: 1px solid #CDD0E34B;
    border-radius: 6px;
  }
  .management_contents .table_footer .pagination .page-item .page-link:focus {
    outline: none !important;
  }
  
  .text_red {
    color: #EA563F !important;
  }/*# sourceMappingURL=index.css.map */

  
.status_active{
  padding: 5px 10px;
    color: #2E8E1D;
    background: #D4F2CF;
  border-radius: 50px;
}

.status_inactive{
  padding: 5px 10px;
    color: #F64949;
    background: #FFDBE0;
  border-radius: 50px;
}

.status_pending {
  padding: 5px 10px;
  color: orange;
  background: #f8e7c6;
  border-radius: 50px;
}