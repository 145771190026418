.hide,
.hide * {
  height: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  opacity: 0;
  transition: opactiy 200ms ease;
}

.animatable_elements {
  /* height: inherit;
  width: inherit;
  padding: inherit;
  margin: inherit;
  border: inherit; */
  opacity: 1;
  transition: opactiy 250ms ease;
}

.cursor_pointer {
  cursor: pointer;
}

.toggle_icon {
  cursor: pointer;
  display: inline-block;
  padding: 4px;
  position: absolute;
  top: 15px;
  right: 0px;
  font-size: 15px;
  color: #4377bb;
  font: 500 12px/20px Spartan;
}

.table {
  border-spacing: 1px 15px!important;
}

.table_row {
  background-color: #fff;
  box-shadow: 0 0 0 1px #cdd0e34b !important;
  border-radius: 15px;
}
