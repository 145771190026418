/* Toggle Button */
.onoffswitch {
    position: relative;
    width: 90px;
    margin-right: 30px;
  }
  
  .onoffswitch.yes_no {
    width: 70px;
  }
  
  .onoffswitch.yes_no .onoffswitch_inner:before {
    content: "Yes" !important;
  }
  
  .onoffswitch.yes_no .onoffswitch_inner:after {
    content: "No" !important;
    color: #ff0000 !important;
  }
  
  .onoffswitch.yes_no .onoffswitch_switch {
    right: 46px !important;
  }
  
  .onoffswitch.enable_disable .onoffswitch_inner:before {
    content: "Enable" !important;
    color: #b9b9b9;
  }
  
  .onoffswitch.enable_disable .onoffswitch_inner:after {
    content: "Disable" !important;
    color: #b9b9b9;
  }
  
  .onoffswitchbtn{
    display: flex;
  }
  /* .onoffswitch.enable_disable .onoffswitch_label {
    border: 1px solid #b9b9b9;
  }
  
  .onoffswitch.enable_disable .onoffswitch_switch {
    background: #b9b9b9;
    border: 1px solid #b9b9b9;
  } */
  
  .onoffswitch_checkbox {
    display: none;
  }
  
  .onoffswitch_label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ff0000;
    border-radius: 16px;
    margin-bottom: 0 !important;
  }
  
  .onoffswitch_inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  
  .onoffswitch_inner:before,
  .onoffswitch_inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    font: 500 12px/34px Spartan;
    box-sizing: border-box;
  }
  
  .onoffswitch_inner:before {
    content: "Enable";
    padding-left: 10px;
    background-color: transparent !important;
    color: #2e8e1d;
  }
  
  .onoffswitch_inner:after {
    content: "Disable ";
    padding-right: 10px;
    background-color: transparent !important;
    color: #ff0000;
    text-align: right;
  }
  
  .onoffswitch_switch {
    display: block;
    width: 20px;
    /* margin: auto; */
    background: #ff0000;
    border: 1px solid #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    bottom: 0;
    right: 65px;
    transition: all 0.3s ease-in 0s;
    height: 20px;
  }

  .individualProductList {
    border: 1px solid #CDD0E34B;
    border-radius: 15px;
    background-color: #fff;
    padding: 30px;
  }

  .individualProductListItemName {
    display: 'flex';
  }

  /* .individualProductListItemName span {
    font: 600 16px/20px Spartan;
    color: #2e2e2e;
    margin-bottom: 12px;
  } */

  .individualProductListItemSlug {
    margin-left: 17px;
  }  
  
  .onoffswitch_checkbox:checked + .onoffswitch_label {
    border: 1px solid #2e8e1d;
  }
  
  .onoffswitch_checkbox:checked + .onoffswitch_label .onoffswitch_switch {
    background: #2e8e1d;
    border: 1px solid #2e8e1d;
  }
  
  .onoffswitch_checkbox:checked + .onoffswitch_label .onoffswitch_inner {
    margin-left: 0;
  }
  
  .onoffswitch_checkbox:checked + .onoffswitch_label .onoffswitch_switch {
    right: 5px !important;
  }
  
  /* Toggle button end */
  
  .upload_img_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* cursor: pointer; */
  }
  
  .upload_img_header a {
    font: 500 12px/20px Spartan;
    color: #4377bb;
  }
  
  .add_image_card {
    min-height: 260px;
    max-height: 260px;
    overflow: auto;
  }

  .add_featureImage_card {
    min-height: 167px;
    max-height: 260px;
    overflow: auto;
  }

  .add_featureImage_card .upload_img {
    width: 100% !important;
  }
  
  .added_image {
    vertical-align: top;
    width: 107px;
    height: 107px;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 1px solid #cdd0e3;
    border-radius: 10px;
    padding: 5px;
  }
  
  .upload_img {
    padding: 10px;
    border: 1px dashed #cdd0e3;
    border-radius: 10px;
    width: 140px;
    height: auto;
    display: inline-block;
  }
  
  .upload_img img {
    margin-bottom: 10px;
    width: 54px;
    height: 36px;
  }
  
  .upload_img .btn {
    background-color: transparent;
    border: 1px solid #afa7a0;
    font-size: 12px;
    margin-bottom: 10px;
    color: #9a8776;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upload_img span {
    font: 500 10px/17px Spartan;
    color: #b9b9b9;
    display: block;
  }
  
  .label_toggle {
    min-height: 120px;
  }
  
  .mt_32 {
    margin-top: 32px;
  }
  
  .table_heading_toggle {
    margin-bottom: 25px;
  }
  
  .table_heading_toggle h4 {
    display: inline-block;
  }
  
  .loading .ant-spin-dot-item{
    background-color: #9a8776;
  }
 .storeupload_img input[type="file"] {
  display: none;
}
.storeupload_img{
  padding: 8px 30px!important;
  font-size: 14px;
}
.upload_drop_file{
  max-width: 100px;
  text-align: center;
  margin: auto;
}
.upload_drop_file span{
  font-size: 14px;
}
  .management_table_product .media {
    display: inline-flex !important;
    border: 1px solid #cdd0e3;
    border-radius: 10px;
    padding: 5px 20px;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .management_table_product .media h5 {
    font: 600 10px/20px Spartan;
    color: #2d2424;
    margin-bottom: 0px;
  }
  
  .management_table_product .media p {
    font: 400 10px/20px Spartan;
    color: #2d2424;
    margin-bottom: 0px;
  }
  
  .management_table_product .media img {
    height: 60px;
    width: 60px;
  }
  
  .management_table_product .media .close_icon {
    height: 12px;
    width: 12px;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
  .custom_control_input:checked ~ .custom_control_label::before {
    background-color: #9a8776 !important;
  }
  
  .custom_control_input:checked ~ .custom_control_label::before {
    color: #9a8776 !important;
    border: 1px solid #9a8776 !important;
    box-shadow: none !important;
  }
  
  .custom_control_input:checked ~ .custom_control_label::before {
    border-color: #007bff;
    background-color: #007bff;
  }
  /* .custom_radio .custom_control_input:checked ~ .custom_control_label::after  */
  .custom_control_input:checked ~ .custom_control_label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    cursor: pointer !important;
  }
  .add_featureImage_card .storeaddupload_img{
    width: 200px!important;
    margin: auto;
    display: block;
  }
  .add_user_role_default{
    color:red;
  }
  .edit_category_update_user_date p{
    white-space: nowrap;
    font: normal normal 600 10px/20px Spartan;
    letter-spacing: 0px;
    color:#B9B9B9;
    margin-bottom: 3px;
    margin-left: 15px;
  }
  .edit_category_update_user_date span{
    color:#000;
  }