.form_control:focus {
  box-shadow: none !important;
  border-color: #cdd0e34b !important;
}

.form_control {
  border: 1px solid #cdd0e34b;
  padding: 5px 15px;
  border-radius: 10px;
  font: 500 14px/20px Spartan;
  color: #2e2e2e;
}

.btn {
  font: 600 12px Spartan;
  border: 1px solid #9a8776;
  border-radius: 46px;
  padding: 10px 20px;
}
.btn img {
  vertical-align: bottom;
  margin-right: 5px;
}

.btn:focus {
  box-shadow: none !important;
}

.btn_borderd {
  color: #9a8776;
  background-color: transparent;
}
.btn_borderd:hover {
  color: #fff !important;
  background-color: #9a8776 !important;
}

.btn_filled {
  color: #fff !important;
  background-color: #9a8776 !important;
}
.btn_filled:hover {
  text-decoration: underline;
}

.secondry_title {
  font: 500 12px/20px Spartan;
  color: #3f4544;
  margin-bottom: 12px !important;
}

input::-moz-placeholder {
  color: #b9b9b9;
}

input:-ms-input-placeholder {
  color: #b9b9b9;
}

input::placeholder {
  color: #b9b9b9;
}

.management_header_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: #fff;
}
.management_header_logo .management_info_img img {
  margin-left: 10px;
  height: 32px;
  width: 32px;
  box-shadow: 0px 5px 15px #0000001a;
  border-radius: 50%;
}

.management_sidebar .list_group {
  margin-top: 40px;
  margin-bottom: 40px;
}
.management_sidebar .list_group_item {
  border: none !important;
  padding: 8px 0px;
  font: 500 14px Spartan;
  color: #2d2424;
  margin-top: 15px;
}
.management_sidebar .list_group_item:hover {
  color: #9a8776;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-right: 5px solid #9a8776 !important;
}
.management_sidebar .list_group_item img {
  height: 16px;
  width: 16px;
  margin: 0px 25px;
}
.management_sidebar .list_group_item.active {
  background-color: transparent;
}
.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}
.management_sidebar .list_group_sub_item {
  padding-left: 90px !important;
  border: none !important;
  padding-top: 8px;
  padding-bottom: 8px;
  font: 500 14px Spartan;
}
.management_sidebar .list_group_sub_item:hover {
  color: #9a8776;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-right: 5px solid #9a8776 !important;
}
.management_sidebar .admin_sidebar_footer {
  margin: 0px 25px;
}
.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px Spartan;
  color: #3f4544;
  margin-bottom: 0px !important;
}
.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px Spartan;
  color: #3f4544;
  display: block;
}

.management_contents {
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-top-left-radius: 40px;
  padding: 50px;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.management_contents .management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e8e8e8;
}
.management_contents .management_header h1 {
  font: 600 24px/33px Spartan;
  color: #3f4544;
}
.management_contents .management_header h1 img {
  margin-right: 20px;
}
.management_contents .management_header .form_control {
  border: 1px solid #cdd0e34b;
  border-radius: 46px;
  display: inline;
  width: auto;
  font-weight: 600 !important;
  font-size: 12px;
  color: #2d2424;
  padding: 5px 15px;
  height: auto;
}
.management_contents .management_details h4 {
  font: 600 16px/20px Spartan;
  color: #2e2e2e;
  margin-bottom: 12px;
}
.management_contents .management_details .management_details_contents {
  margin-bottom: 25px;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_contents_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_contents_header
  a {
  font: 500 12px/20px Spartan;
  color: #4377bb;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form {
  border: 1px solid #cdd0e34b;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
}
.management_contents
  .management_details
  .management_details_contents
  .form_control {
  border: 1px solid #cdd0e3;
  border-radius: 10px;
  font: 500 14px/20px Spartan;
}
.management_contents .management_details .management_details_contents label {
  font: 500 12px/20px Spartan;
  color: #2e2e2e;
}
.management_contents
  .management_details
  .management_details_contents
  .btn:focus {
  outline: none !important;
}
.management_contents
  .management_details
  .management_details_contents
  input::-moz-placeholder {
  color: #b9b9b9;
}
.management_contents
  .management_details
  .management_details_contents
  input:-ms-input-placeholder {
  color: #b9b9b9;
}
.management_contents
  .management_details
  .management_details_contents
  input::placeholder {
  color: #b9b9b9;
}

.file_import {
  max-height: 200px;
  overflow: auto;
}
.file_import .choose_file {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #afa7a0;
  border-radius: 5px;
  font: 500 10px/12px Spartan;
  color: #9a8776;
  margin-bottom: 5px;
  margin-right: 10px;
}
.file_import .choosed_file_name {
  display: inline-block;
  padding: 6px 12px;
  color: #747474;
  background-color: #f2efef;
  border-radius: 5px;
  font: 500 10px/12px Spartan;
  margin-right: 10px;
  margin-bottom: 5px;
}
.file_import .close_icon {
  height: 10px;
  width: 10px;
  vertical-align: middle;
  margin-left: 5px;
}
.bulkuploadimageheight{
  max-height: 275px;
  overflow-y: auto;
}
.bulkuploadimageheight::-webkit-scrollbar {
  width: 4px;
}
.bulkuploadimageheight::-webkit-scrollbar-thumb {
  background: rgba(31, 30, 30, 0.466); 
  border-radius: 7px;
}

.bulk_img_list{
  position: relative;
  display: inline-block;
}
.added_image{
  vertical-align: top;
  width: 90px;
  height: 90px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid #cdd0e3;
  border-radius: 10px;
  padding: 3px;
}
img.bulk_close_icon{
  cursor: pointer;
}
.bulk_close_icon{
position: absolute;
top: -4px;
left: 80px;
}