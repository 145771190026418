.admin_management {
  outline: none !important;
}

.admin_management .row_margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.admin_management .row_margin .col_padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.form_control:focus {
  box-shadow: none !important;
  border-color: #CDD0E34B !important;
}

.form_control {
  border: 1px solid #CDD0E34B;
  padding: 5px 15px;
  border-radius: 10px;
  font: 500 14px/20px Spartan;
  color: #2E2E2E;
}

.btn {
  font: 600 12px Spartan;
  border: 1px solid #9A8776;
  border-radius: 46px;
  padding: 10px 20px;
}

.btn:focus {
  box-shadow: none !important;
}

.btn_borderd {
  color: #9A8776;
  background-color: transparent;
}

.btn_filled {
  color: #fff !important;
  background-color: #9A8776 !important;
}
.btn_filled_close {
  color: #fff !important;
  background-color: #9A8776 !important;
  margin-right: 8px;
}
.modal_header {
  border-bottom: none !important;
  padding: 0 !important;

}
.modal_title {
  font: 400 30px/34px Cormorant Garamond;
  color: #9A8776;
  margin-left: 80px;
 }
.close_icon {
  border: none;
  background: #fff;
  font-size: 25px; 
  height: 18px;
  width: 18px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.secondry_title {
  font: 500 12px/20px Spartan;
  color: #3F4544;
  margin-bottom: 12px !important;
}
.Shipping_label{
  font: 500 12px/20px Spartan;
  color: #3F4544;
}
.shipping_input{
  margin-bottom: 6px !important;
}
.shipping_state {
  width: 100%;
  border: 1px solid #cdd0e3;
  border-radius: 10px;
  margin-bottom: 14px;
}

.management_header_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: #fff;
}

.management_header_logo .management_info_img img {
  margin-left: 10px;
  height: 32px;
  width: 32px;
  box-shadow: 0px 5px 15px #0000001a;
  border-radius: 50%;
}

.management_sidebar .list_group {
  margin-top: 40px;
  margin-bottom: 40px;
}

.management_sidebar .list_group_item {
  border: none !important;
  padding: 8px 0px;
  font: 500 14px Spartan;
  color: #2D2424;
  margin-top: 15px;
}

.management_sidebar .list_group_item.active {
  background-color: transparent;
}

.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}

.management_sidebar .list_group_item:hover {
  color: #9A8776;
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-right: 5px solid #9a8776 !important;
}

.management_sidebar .list_group_item img {
  height: 16px;
  width: 16px;
  margin: 0px 25px;
}

.management_sidebar .list_group_sub_item {
  padding-left: 90px !important;
  border: none !important;
  padding-top: 8px;
  padding-bottom: 8px;
  font: 500 14px Spartan;
}

.management_sidebar .list_group_sub_item:hover {
  color: #9A8776;
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-right: 5px solid #9a8776 !important;
}

.management_sidebar .admin_sidebar_footer {
  margin: 0px 25px;
}

.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px Spartan;
  color: #3F4544;
  margin-bottom: 0px !important;
}

.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px Spartan;
  color: #3F4544;
  display: block;
}

.management_contents {
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-top-left-radius: 40px;
  padding: 50px;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}

.management_contents .management_header {
  margin-bottom: 25px;
}

.management_contents .management_header h1 {
  font: 600 24px/33px Spartan;
  color: #3F4544;
  margin-bottom: 10px;
}

.management_header_sub_text {
  font: 600 16px/33px Spartan;
  margin-left: 10px;
}

.management_contents .management_header h1 img {
  margin-right: 30px;
}

.management_contents .management_header {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.management_contents .management_header .management_header_btn .btn {
  background-color: transparent !important;
  margin-left: 10px;
  color: #9a8776;
  border: none !important;
  padding: 0 !important;
  margin-bottom: 15px;
  font: 600 16px/20px Spartan !important;
}

.management_contents .management_header .management_header_order_id {
  font: 500 17px/20px Spartan;
  color: #2D2424;
  text-align: end;
}

.management_contents .management_header .management_header_info {
  font: 500 12px/20px Spartan;
  color: #2D2424;
  margin-left: 60px;
  vertical-align: middle;
  display: inline-block;
}

.management_contents .management_details .management_details_contents .border_green {
  border-color: #B6E1AF !important;
}

.management_contents .management_details .management_details_contents h4 {
  font: 600 16px/20px Spartan;
  color: #2E2E2E;
  margin-bottom: 12px;
}

.management_contents .management_details .management_details_contents .product_details {
  display: flex;
  justify-content: space-between;
}

.management_contents .management_details .management_details_contents .product_details .product_img {
  height: 70px;
  width: 70px;
  padding: 5px;
  box-sizing: content-box;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}

.order_action_dropdown {
  border: 1px solid #A39486;
  border-radius: 10px;
  padding: 10px 20px;
  font: 500 14px/20px Spartan;
  color: #A39486;
  cursor: pointer;
}

.order_action_dropdown img {
  margin-left: 10px;
}

.order_action_dropdown_menu a {
  text-decoration: none !important;
  cursor: pointer;
}

.management_contents .management_details .management_details_contents .product_details span {
  font: 500 12px/24px Spartan;
  color: #2D2424;
}

.management_contents .management_details .management_details_contents .product_details .product_name {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.management_contents .management_details .management_details_contents .product_details .product_name .product_name_value .product_title {
  /* text-decoration: underline; */
  font: 600 12px/20px Spartan;
  color: #9A8776;
  display: block;
  text-decoration: underline;
}

.management_contents .management_details .management_details_contents .product_details .product_value {
  min-height: 50px;
  display: inline-flex;
  align-items: center;
}

.management_contents .management_details .management_details_contents .customer_notes {
  min-height: 160px;
}

.management_contents .management_details .management_details_contents .customer_notes span {
  font: 400 12px/20px Spartan;
  color: #3F4544;
}

.management_contents .management_details .management_details_contents .management_details_form {
  border: 1px solid #CDD0E34B;
  border-radius: 15px;
  background-color: #fff;
  padding: 30px;
}

.management_contents .management_details .management_details_contents .management_details_form .admin_pament_info {
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 25px;
  margin-bottom: 35px;
}

.management_contents .management_details .management_details_contents .management_details_form .admin_pament_info .admin_payment_invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.management_contents .management_details .management_details_contents .management_details_form .admin_pament_info .admin_payment_invoice .secondry_title {
  margin-bottom: 0px !important;
}

.management_contents .management_details .management_details_contents .management_details_form .admin_pament_info .admin_payment_invoice a {
  text-decoration: underline;
  font: 500 12px/20px Spartan;
  color: #9A8776;
}

.management_contents .management_details .management_details_contents .management_details_form .admin_pament_info span {
  font: 600 12px/20px Spartan;
  color: #2E2E2E;
  display: block;
}

.management_contents .management_details .management_details_contents .management_details_form .media_card.active {
  background-color: #F9F9F9;
}

.management_contents .management_details .management_details_contents .management_details_form .media_card img {
  border-radius: 14px;
  height: 25px;
  width: 25px;
}

.management_contents .management_details .management_details_contents .management_details_form .media_card .media_body {
  font: 500 12px/24px Spartan;
  color: #2D2424;
}

.management_contents .management_details .management_details_contents .management_details_form .media_card .media_body h5 {
  font: 600 18px/19px Spartan;
  color: #2E8E1D;
}

.management_contents .management_details .management_details_contents .management_details_form .media_card .media_body a {
  color: #2E8E1D;
  text-decoration: underline;
}

.management_contents .management_details .management_details_contents .admin_payment {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.management_contents .management_details .management_details_contents .admin_payment span:first-child {
  font: 500 13px/15px Spartan;
  color: #3D3D3D;
}

.management_contents .management_details .management_details_contents .admin_payment span:last-child {
  font: normal normal 600 13px/15px Spartan;
  color: #3D3D3D;
}

.management_contents .management_details .management_details_contents .admin_payment:last-child {
  border-top: 1px solid #EEEEEE;
  padding-top: 15px;
  margin-bottom: 0px;
}

.management_contents .management_details .management_details_contents .admin_payment:last-child span:first-child {
  font: 700 17px/20px Spartan;
}

.management_contents .management_details .management_details_contents .admin_payment:last-child span:last-child {
  font: 600 16px/19px Spartan;
}

.management_contents .management_details .management_details_contents .admin_customer .admin_customer_details span {
  font: 600 14px/24px Spartan;
  color: #3F4544;
  display: block;
}
.admin_customer_contact{
  font: 500 12px/20px Spartan;
  color: #3F4544;
  margin-bottom: 12px !important;
}
.admin_customer_contact:hover{
color: rgb(155, 153, 150);
}
.admin_title_with_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin_title_with_link .admin_link {
  font: 500 12px/20px Spartan;
  color: #A39486;
}

.admin_title_with_link .admin_link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.management_contents .management_details .management_details_contents .admin_customer .border_bottom {
  border-bottom: 1px solid #D4D4D4;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.invoice_btn {
  width: 100% !important;
  border: 1px solid #A39486;
  border-radius: 5px;
  padding: 15px !important;
  background-color: transparent;
  font: 500 14px/20px Spartan !important;
  margin-top: 10px;
  color: #A39486;
}

.management_contents .management_details .management_details_contents .admin_customer .admin_customer_transactions .secondry_title {
  margin-bottom: 0px !important;
}
.accordion_card_header_count{
  position:relative;
}
.accordion_card_header_count span{
  position: absolute;
  font: normal normal normal 20px/20px Spartan;
  letter-spacing: 0px;
  color: #FFFFFF;
  top: 18px;
  text-align: center;
  display: block;
  width: 100%;
}
.select_refund_reason_checkbox span{
  font: normal normal 600 13px/19px Spartan;
  letter-spacing: 0px;
  color: #3D3D3D;
}
.select_refund_reason_checkbox input[type=checkbox] {
    zoom: 1.5;
    margin-right: 12px;
    position: relative;
    top: 3px;
}
/* Bootstrap Checkbox Customization */
.custom_checkbox {
  line-height: 22px !important;
}

.custom_checkbox .custom_control_input:checked~.custom_control_label::before {
  background-color: #9A8776;
  box-shadow: 0 0 3px #9A8776;
  width: 17px;
  height: 17px;
}

.custom_checkbox .custom_control_input~.custom_control_label::before {
  width: 17px;
  height: 17px;
  box-shadow: 0 0 3px #3F4544 !important;
}

.custom_checkbox .custom_control_input:checked~.custom_control_label::after {
  top: 2px !important;
  left: -26px !important;
  width: 22px !important;
  height: 22px !important;
}

.custom_checkbox .custom_control_input:focus~.custom_control_label::before {
  box-shadow: 0 0 3px #9A8776;
}

.custom_checkbox .custom_control_input:active~.custom_control_label::before {
  box-shadow: 0 0 3px #9A8776;
  background: rgba(154, 135, 118, 0.9);
}

.custom_checkbox .custom_control_label::before {
  border-radius: 0.25rem;
}

.custom_control_label::before {
  background-color: #fff;
}

.custom_control_label span {
  padding-left: 5px;
  display: inline-block;
  font: 500 12px Spartan;
  letter-spacing: 0px;
  color: #2E2E2E;
}

/* Order Progressbar */
.grayesh_orange {
  color: #9a8776 !important;
}

.red_lite {
  color: #CE6E6E !important;
}

.order_events {
  display: block;
  text-align: left;
  padding: 10px 0px;
  width: 100%;
}

.order_events .order_events_header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.order_events .order_events_header h4 {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0px;
}

.order_events .order_events_header .order_status_dropdown {
  font: 600 10px/13px Spartan;
  color: #9A8776;
  cursor: pointer;
}

.order_status_dropdown img {
  height: 10px;
  width: 10px;
  margin-left: 10px;
}

.order_events .order_events_values {
  width: 100%;
}

.order_events .order_events_values .order_events_details {
  width: 100%;
  min-height: 55px;
  height: 100%;
  /* max-height: 150px; */
  display: flex;
  min-width: 100px;
  position: relative;
  z-index: 0;
}

.order_events .order_events_values:not(:last-child) .order_events_details:before {
  content: "";
  position: absolute;
  width: auto;
  height: 100%;
  border-left: 1px dashed #9A8776;
  top: 0px;
  left: 7px;
  z-index: -1;
}

.order_events .order_events_values .order_events_details .order_events_content {
  margin-left: 30px;
  width: 100%;
}

.order_events .order_events_values:not(:last-child) .order_events_details .order_events_content {
  margin-bottom: 30px;
}

.order_events .order_events_values .order_events_circle {
  background: #C8AC92;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.order_events .order_events_values .order_events_circle.sucess {
  background-color: #2E8E1D;
}

.order_events .order_events_values .order_events_status.sucess {
  color: #2E8E1D;
}

.order_events .order_events_values .order_events_circle.failed {
  background-color: #F64949;
}

.order_events .order_events_values .order_events_status.failed {
  color: #F64949;
}

.order_events .order_events_values .order_events_status {
  font: normal normal 600 12px/14px Spartan;
  color: #3D3D3D;
  margin-bottom: 5px;
}

.order_events .order_events_values .order_events_info {
  font: 500 10px/16px Spartan;
  color: #3D3D3D;
  margin-bottom: 5px;
  background: #EEEEF6;
  border-radius: 8px;
  padding: 10px 20px;
  width: fit-content;
}

.order_events .order_events_values .order_events_info h2 {
  font: 600 12px/14px Spartan;
  color: #3D3D3D;
  margin-bottom: 10px;
}

.order_events .order_events_values .order_events_date {
  font: 500 10px/16px Spartan;
  color: #747474;
  margin-bottom: 5px;
}

.order_events .order_events_values .order_events_rma_id {
  font: 500 10px/12px Spartan;
  color: #747474;
  margin-bottom: 5px;
}

.order_events .order_events_values .order_events_desc_list {
  font: 600 10px/12px Spartan;
  margin-bottom: 5px;
}

.order_events .order_events_values .order_events_comments {
  position: relative;
  margin-bottom: 5px;
}

.order_events .order_events_values .order_events_comments .order_events_btn {
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0 !important;
}

.credit_momo_btn {
  cursor: pointer;
  margin: 0 !important;
}

.order_events .order_events_values .order_events_comments input {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #F0F1F7;
  border-radius: 8px;
  padding: 10px 50px 10px 20px;
  font: 500 12px/16px Spartan;
  letter-spacing: 0px;
  color: #747474;
}

.order_events .order_events_values .order_events_desc_list .order_events_desc_list_heading {
  color: #3D3D3D;
  margin-right: 3px;
}

.order_events .order_events_values .order_events_desc_list .order_events_desc_list_description {
  color: #B4B4B4;
}

.order_events .order_events_values .order_events_desc_list .order_events_desc_list_link {
  color: #A39486;
  cursor: pointer;
  text-decoration: none;
}

.order_events .order_events_values .order_events_desc_list .order_events_desc_list_link:hover {
  color: #A39486;
  cursor: pointer;
  text-decoration: underline;
}

.order_events_btn {
  font: 400 11px/16px Spartan;
  padding: 5px 10px;
  display: inline-block;
  border: 1px solid #A39486;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  /* margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 355px;  */
  margin: 20px 0 10px 352px;
 
}

.order_events .order_events_values .order_events_btn_filled {
  color: #ffffff;
  background-color: #A39486;
}

.order_events .order_events_values .order_events_btn_borderd {
  color: #A39486;
}

.order_events .order_events_values .order_events_btn_borderd:hover {
  background-color: #A39486;
  color: #ffffff;
}

.order_events .order_events_values .order_events_content .order_events_delivery_person {
  background-color: #FFFFFF;
  border: 1px solid #F0F1F7;
  border-radius: 8px;
  margin-bottom: 5px;
  padding: 20px;
}

.order_events .order_events_values .order_events_content .order_events_delivery_person label {
  font: 500 10px/16px Spartan;
  letter-spacing: 0px;
  color: #747474;
}

.order_events .order_events_values .order_events_content .order_events_delivery_person .form_control {
  border: 1px solid #F0F1F7 !important;
  border-radius: 8px !important;
  font: 500 10px/16px Spartan;
  color: #3D3D3D;
  margin-bottom: 0px !important;
}

.order_events .order_events_values .order_events_customer_request {
  margin-bottom: 5px;
  border: 1px solid #e3dfdf;
  border-radius: 6px;
  padding: 5px 10px;
}

.order_events .order_events_values .order_events_customer_request .order_events_customer_request_info {
  font: 600 10px/12px Spartan;
  color: #3D3D3D;
  margin-bottom: 5px;
}

/* onoffswitch */
.onoffswitch {
  position: relative;
  width: 90px;
  margin-right: 30px;
}

.onoffswitch.yes_no {
  width: 70px;
}

.onoffswitch.yes_no .onoffswitch_inner:before {
  content: "Yes" !important;
}

.onoffswitch.yes_no .onoffswitch_inner:after {
  content: "No" !important;
  color: red !important;
}

.onoffswitch.yes_no .onoffswitch_switch {
  right: 46px !important;
}

.onoffswitch.enable_disable .onoffswitch_inner:before {
  content: "Enable" !important;
  color: #B9B9B9;
}

.onoffswitch.enable_disable .onoffswitch_inner:after {
  content: "Disable" !important;
  color: #B9B9B9;
}

.onoffswitch.enable_disable .onoffswitch_label {
  border: 1px solid #B9B9B9;
}

.onoffswitch.enable_disable .onoffswitch_switch {
  background: #B9B9B9;
  border: 1px solid #B9B9B9;
}

.onoffswitch_checkbox {
  display: none;
}

.onoffswitch_label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid red;
  border-radius: 16px;
  margin-bottom: 0 !important;
}

.onoffswitch_inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch_inner:before,
.onoffswitch_inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  font: 500 12px/34px Spartan;
  box-sizing: border-box;
}

.onoffswitch_inner:before {
  content: "Active";
  padding-left: 10px;
  background-color: transparent !important;
  color: #2E8E1D;
}

.onoffswitch_inner:after {
  content: "Inactive";
  padding-right: 10px;
  background-color: transparent !important;
  color: #2E8E1D;
  text-align: right;
}

.onoffswitch_switch {
  display: block;
  width: 20px;
  /* margin: auto; */
  background: red;
  border: 1px solid red;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  bottom: 0;
  right: 65px;
  transition: all 0.3s ease-in 0s;
  height: 20px;
}

.onoffswitch_checkbox:checked+.onoffswitch_label {
  border-color: #2E8E1D !important;
}

.onoffswitch_checkbox:checked+.onoffswitch_label .onoffswitch_inner {
  margin-left: 0;
}

.onoffswitch_checkbox:checked+.onoffswitch_label .onoffswitch_switch {
  right: 5px !important;
  background: #2E8E1D !important;
  border: 1px solid #2E8E1D !important;
}

/* onoffswitch end */

.order_events .order_events_values .order_events_desc_list .order_events_desc_list_tag {
  display: inline-block;
  padding: 7px 14px;
  border-radius: 5px;
  margin-right: 10px;
}

.approved {
  color: #2E8E1D !important;
  background-color: #D4F2CF;
}

.rejected {
  color: #F64949;
  background-color: #FFDBE0;
}

/* Order Progressbar End*/


/* Refund Details */
.product_details_contents {
  display: table-row !important;
  border-bottom: 1px solid #EEEDED;
}

.product_details_img {
  position: relative;
}

input[type=checkbox].product_details_check_box   {
  zoom: 1.5;
  position: absolute;
  left: 55px;
  top: 0;
}
input:checked.product_details_check_box{
  background-color: #FA9E57;
}
.product_details_contents .product_img {
  padding: 20px 15px !important;
}

.product_details_check_box {
  position: absolute;
  left: 85px;
  top: 5px
}

.product_details_heading th {
  padding: 0 !important;
  width: 25%;
}


.product_details_heading th:first-child {
  width: 40%;
}

.product_details_contents td {
  padding: 0 0 30px 0 !important;
  vertical-align: middle;
}

.management_details_form .product_details_contents:not(:first-child) td {
  padding-top: 30px !important;
}

/* Refund Details End */

/* Refund break up */
.refund_breakup table thead th {
  width: 25%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  text-align: center;
}

.refund_breakup table thead th:first-child {
  width: 40%;
  text-align: left;
}

.refund_breakup table tbody td {
  padding: 10px 0 !important;
}

.refund_breakup_title {
  font: 600 18px/20px Spartan;
  color: #2E2E2E;
}

.refund_breakup_heading h3 {
  margin-bottom: 0px !important;
  font: normal normal 600 14px/20px Spartan;
  letter-spacing: 0px;
  color: #3D3D3D
}

.refund_breakup_contents {
  font: 500 12px/24px Spartan;
  color: #2D2424;
  position: relative;
}
.refund_breakup_contents span{
  padding-left: 25px;
}

.refund_breakup_contents span.product_value {
  display: block;
  text-align: center;
  width: 100%;
  padding-left: 0px;
}
.refund_breakup_contents span.product_value input{
text-align: center;
max-width: 130px;
border: 1px solid #ccc;
border-radius: 7px;
}


.refund_breakup_contents tbody td{
text-align: center;
}


.refund_breakup_checkbox {
  position: absolute;
  top: 12px;
}

.refund_breakup_info_img {
  margin-left: 15px;
  vertical-align: middle;
}

.refund_border_bottom {
  border-bottom: 1px solid #EEEDED;
  margin-bottom: 15px;
}
.refund_border_bottom td:first-child{
text-align: left;
}
.refund_border_bottom td{
text-align: center;
}
.color_red {
  color: #BC3131 !important;
}

.refund_border_text_bold {
  font: 700 14px/20px Spartan;
  color: #3F4544;
}

.auto_refund {
  margin-left: 30px;
}

.auto_refund_text {
  font: 500 14px/20px Spartan;
  color: #3F4544;
  margin-right: 10px;
}

.auto_refund_status {
  font: 600 14px/14px Spartan;
  letter-spacing: 0px;
  color: #2E8E1D;
  background-color: #D4F2CF;
  padding: 5px 10px;
  border-radius: 22px;
}

/* Refund break up end */

.Refund_Reason_head {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  font: normal normal 600 16px/20px Spartan;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;

}

.Refund_Reason_desc_head {
  font: normal normal 600 11px/16px Spartan;
  letter-spacing: 0px;
  color: #3D3D3D;
  opacity: 1;

  margin-left: 20px;
}

.Refund_Reason_desc {
  text-align: left;
  font: normal normal medium 12px/20px Spartan;
  letter-spacing: 0px;
  color: #737373;
  opacity: 1;
  padding: 20px;
}

style.Refund_acc_card_header_btn {
  width: 100%;
}

.Refund_Reason_select {
  width: 100%;
}

.Refund_Reason_textarea {
  border: 1px solid #D8D3CF;
  border-radius: 4px;
  opacity: 1;
  margin-top: 16px;
  margin-bottom: 18px;
  width: 100%;
  height: 112px;
}

.Refund_Refund_method_coupon {
  opacity: 1;
  text-align: left;
  font: normal normal medium 14px/16px Spartan;
  letter-spacing: 0px;
  color: #A39486;
  background-color: #F6F0EC;
  width: 164px;
  margin-left: 10px;
  padding: 10px;
}

.Refund_btn {
  border: 1px solid #9A8776;
  opacity: 1;
  width: 200px;
  height: 43px;
  text-align: center;
  font: normal normal normal 14px/20px Spartan;
  letter-spacing: 0px;
  color: #9A8776;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 1px;
  background-color: white;
  margin-left: 20px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.Refund_btn_span {
  margin-left: 600px;
}

.Refund_method_span {
  margin-left: 600px;
  color: red;
}

.Refund_method_btn {
  border: 1px solid #9A8776;
  opacity: 1;
  width: 200px;
  height: 43px;
  text-align: center;
  font: normal normal normal 14px/20px Spartan;
  letter-spacing: 0px;
  color: red;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 1px;
  background-color: white;
  margin-left: 20px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.Refund_admin {
  padding: 20px;
}

.Refund_table_acc {
  width: 100%;
}

.Refund_acc {
  width: 91%;
}

.Refund_acc_card {
  width: 100%;
  border-color: white;
  padding: 20px;
  margin-right: 20px;
}

.Refund_acc_card_header_btn {
  width: 100%;
}


/* Accordion */
.product_accoridon {
  border-bottom: 1px solid #D8D3CF;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.accordion_card {
  border: 1px solid #D8D3CF;
  border-radius: 11px;
  width: 100%;
  position: relative;
  padding: 15px 0;
  margin-bottom: 15px;
}

.accordion_arrow {
  position: absolute;
  right: 10px;
  top: 10px;
}

.accordion_arrow_rotate {
  transform: rotate(180deg);
  position: absolute;
  right: 10px;
  top: 10px;
}

.accordion_card button {
  text-decoration: none !important;
}

.accordion_card_header {
  text-align: left;
  padding: 0 !important;
  background-color: transparent;
  border: none;
  display: flex;
}

.accordion_card_product {
  display: flex;
  margin-left: 30px;
  justify-content: space-between;
  width: 100%;
}

.accordion_card_product_img {
  height: 60px;
  width: 60px;
  padding: 5px;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
}

.accordion_card_product_img img {
  height: 100%;
  width: auto;
}

.accordion_card_product_total {
  padding-right: 50px;
}

.accordion_card_product_title {
  font: 500 12px/20px Spartan;
  color: #737373;
}

.accordion_card_product_description {
  font: 600 14px/20px Spartan;
  color: #3D3D3D;
}

.accordion_card_body {
  margin-top: -20px;
  padding: 0 10px;
}

.accordion_card_product_content {
  border-top: 1px solid #D8D3CF;
  padding-top: 10px;
  margin-top: 20px;
  margin-left: 85px;
}

.accordion_card_product_content_img img {
  height: 80px;
  width: 80px;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.accordion_card_product_content_reson {
  margin-bottom: 15px;
}

.accordion_card_product_content_reson_dot {
  height: 6px;
  width: 6px;
  background-color: #3D3D3D;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 10px;
}

.accordion_card_product_content_reson_text {
  font: 600 11px/16px Spartan;
  color: #3D3D3D;
  display: inline-block;
  margin-right: 50px;
}

.accordion_card_product_content_reson_description {
  font: 500 12px/20px Spartan;
  color: #737373;
}

/* Accordion End*/

.select_refund_reason {
  margin-left: 95px;
}

.select_refund_reason_title {
  font: 600 16px/20px Spartan;
  color: #747474;
  margin-bottom: 15px;
}

/* Refund Table */
.refund_table_title {
  font: 600 18px/20px Spartan;
  color: #2E2E2E;
  margin-bottom: 20px;
}

.refund_table th {
  width: 25%;
  font: 600 14px/20px Spartan;
  color: #3D3D3D;
}

.refund_table td {
  font: 600 12px/26px Spartan;
  color: #3F4544;
}

.refund_table tr:last-child td {
  font: bold 14px/20px Spartan;
  color: #3F4544;
}

.refund_table_amount_border {
  display: inline-block;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 5px 20px 2px;
  min-width: 120px;
}

.refund_table_border_bottom {
  border-bottom: 1px solid #E4E4E4;
}

.refund_table_color_red {
  color: #BC3131 !important;
}

.refund_table_info_icon {
  display: inline-block;
  margin-left: 30px;
}

/* Refund Table End*/

/* Refund Customer Method */
.refund_customer_method {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.refund_customer_method_title {
  font: 600 12px/20px Spartan;
  color: #3F4544;
  margin-bottom: 10px;
}

.refund_customer_method_btn {
  font: 500 12px/14px Spartan;
  color: #A39486;
  padding: 10px 20px 8px;
  /* border: 1px solid #E4E4E4; */
  border-radius: 5px;
  background-color: #F6F0EC;
  cursor: default;
}

.refund_customer_method_btn:hover {
  color: #A39486;
}

.refund_customer_method_text {
  font: 600 14px/20px Spartan !important;
  color: #BC3131 !important;
}

/* Refund Customer Method End*/

/* Refund Super Admin  */
.refund_super_admin {
  display: flex;
  justify-content: space-between;
}

.refund_super_admin_title {
  font: 600 14px/20px Spartan;
  color: #3F4544;
}

.refund_super_admin_btn {
  font: 500 14px/16px Spartan;
  color: #A39486;
  border: 1px solid #A39486;
  border-radius: 5px;
  padding: 10px 20px 8px;
  display: inline-block;
  margin-left: 10px;
}

.font_small{
  font-size: xx-small;
}

.font_small:hover{
  font-size: xx-small; 
}

.refund_super_admin_btn:hover{
  background-color: #A39486;
  color: white;
}

.order_mark_as_paid_payment_done{
  font: 400 11px/16px Spartan;
  position: relative;
  padding: 5px 10px;
  display: inline-block;
  border: 1px solid #A39486;
  background:#A39486;
  color:#fff;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  margin: 11px 106px 0px 17px;
}

.ccavenue_payu_dropdown{
  border: 1px solid #ced4da;
    padding: 5px 15px;
    border-radius: 5px;
    font: 500 14px/20px Spartan;
    color: #2E2E2E;
    height:calc(1.5em + 1rem + 2px);
}

/* Refund Super Admin  End*/