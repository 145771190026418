.form_control:focus {
    border: 1px solid #DEDEDE !important;
    box-shadow: none !important;
  }
  
  .btn_login {
    width: 100%;
    font: 400 16px/20px Spartan;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 16px 32px;
    background-color: #A39486;
    border-radius: 3px !important;
    margin-bottom: 3px;
    border:none;
    height:49px;
  }
  .btn_login:focus {
    box-shadow: none !important;
  }
  .recap{
    width: 307px;
    border: 2px solid red;
  }
  .recaptcha{
    transform: scale(1.05);
    transform-origin: 0 0;
  }
  .login_bg {
    background-image: url("../assets/loginii.png");
    min-height: 100vh;
    padding: 20px 80px;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .login_banner_img {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
    width: 100%;
  }
  .login_banner_img img {
    width: 90%;
    height: auto;
    margin-top: 5%;
  }
  
  .login {
    /* background-color: #fff; */
    width: 100%;
    min-width: 350px;
    padding: 15px;
    border-radius: 10px;
    /* border: 1px solid #D3CBCB; */
    border-radius: 17px;

  }
  .login_bg_box{
    margin:auto;
    min-width:350px;
    max-width:350px;
  }
  .login_otp_box{
    margin:auto;
    min-width:350px;
    max-width:350px;
  }
  .login .logo_img {
    margin-bottom: 35px;
    text-align: center;
  }
  .login_otp_box .logo_img {
    margin-bottom: 35px;
  }
  .otp_input_field{
    border-radius: 1px;
  }
  .login .logo_img img, .login_otp_box img {
    width: 200px;
    height: 65px;
    margin: auto;
    display: block;
  }
 
  .login_otp_text{
    text-align: center;
  }
  .login_otp_text h4{
    font: normal normal medium 21px/47px Spartan;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 14px;
  }
  .login_otp_text p{
    font: normal normal normal 16px/28px Spartan;
    letter-spacing: 0px;
    color: #F6F7F8;
  }
  .login .title_text {
    font: bold 36px/34px Cormorant Garamond;
    color: #9A8776;
    margin-bottom: 15px;
  }
  .login .welcome_text {
    font: 80 Spartan;
    color: #fbffff;
    margin-bottom: 15px;
    text-align:center;
  }
  .login .welcome_text_welcome{
    font: Arial;
    color: #fbffff;
    margin-bottom: 15px;
    font-size: 1.5rem;
    text-align:center;
  }

  .login .form_group {
    margin-bottom: 24px !important;
  }
  .login .form_control {
    border: 1px solid #DEDEDE;
    border-radius: 3px;
    background-color: #F6F7F8;
    height: 50px;
    margin-bottom: -3px;
  }
  .login .form_control::-moz-placeholder {
    font: 500 12px/16px Spartan;
    color: #9A9A9A;
  }
  .login .form_control:-ms-input-placeholder {
    font: 500 12px/16px Spartan;
    color: #9A9A9A;
  }
  .login .form_control::placeholder {
    font: 500 12px/16px Spartan;
    color: #9A9A9A;
  }
  .login .error_text {
    font: 500 12px/16px Spartan;
    color: #dc3545;
    visibility: hidden;
  }
  .login .error_text.active {
    visibility: visible;
  }
  .login .password_icon {
    position: relative;
  }
  .login .password_icon img {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .login .password_icon input {
    padding-right: 45px;
  }
  .login .forgot_password {
    margin-bottom: 10px;
    text-align: right;
    color: #fdfdfc;
  }
  .login .forgot_password a {
    font: 500 11px/16px Spartan;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
  }
  .login .privacy_policy {
    font: 400 12px/24px Spartan;
    color: #353B3B;
    text-align: center;
  }
  .login .captcha {
    margin-bottom: 20px;
    background-color: #F6F7F8;
  }
  
  @media (max-width: 1024px) {
    .recaptcha{
      transform: scale(1.0);
      transform-origin: 0 0;
    }
  } 
  @media (min-width: 1115px) and (max-width: 1204px) {
    .recaptcha{
      transform: scale(1.05);
      transform-origin: 0 0;
    }
  } 
  @media (max-width: 991px) {
    .recaptcha{
      transform: scale(1.05);
      transform-origin: 0 0;
    }
    .btn_login {
      font: 400 14px/20px Spartan;
      padding: 10px 20px;
      margin-bottom: 1px;
    }
  
    .login_banner_img {
      display: none;
    }
  
    .login_bg {
      padding: 20px;
    }
  
    .login {
      width: 100%;
      min-width: 350px;
    }
    .login .logo_img {
      margin-bottom: 20px;
      text-align: center;
    }
    .login .logo_img img {
      width: 180px;
      height: 55px;
    }
    .login .title_text {
      font: bold 28px/26px Cormorant Garamond;
      margin-bottom: 10px;
    }
    .login .error_text {
      font: 500 11px/16px Spartan;
    }
    .login .password_icon img {
      top: 15px;
      right: 15px;
    }
    .login .password_icon input {
      padding-right: 35px;
    }
    .login .forgot_password {
      margin-bottom: 5px;
    }
    .login .forgot_password a {
      font: 500 11px/16px Spartan;
      color: #fff;
    }
    .login .captcha {
      margin-bottom: 10px;
    }
    .login .privacy_policy {
      font: 400 11px/24px Spartan;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .login {
      padding: 25px 25px 18px 25px;
    }
    .recaptcha{
      transform: scale(1.0);
      transform-origin: 0 0;
    }
  }/*# sourceMappingURL=index.css.map */
  @media (max-width: 768px) {
    .recaptcha{
      transform: scale(1.05);
      transform-origin: 0 0;
    }
  }
  @media (max-width: 480px){
    .recaptcha{
      transform: scale(0.90);
      transform-origin: 0 0;
    }
    .login_bg{
      padding: 0px;
    }
    .login_bg_box{
      min-width:300px;
      max-width:300px;
    }
    .login_otp_box{
      min-width:300px;
      max-width:300px;
    }
    .login{
      min-width: 300px;
    }
  }