.form_control:focus {
    box-shadow: none !important;
    border-color: #CDD0E34B !important;
  }
  
  .form_control {
    border: 1px solid #CDD0E34B;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    font-family: "Spartan", inherit !important;
    color: #2E2E2E;
  }
  
  .btn {
    font: 600 12px Spartan;
    border: 1px solid #9A8776;
    border-radius: 46px;
    padding: 10px 20px;
  }
  
  .btn img {
    vertical-align: bottom;
    margin-right: 5px;
  }
  
  .btn:focus {
    box-shadow: none !important;
  }
  
  .btn_borderd {
    color: #9A8776;
    background-color: transparent;
  }
  .btn_borderd:hover {
    color: #fff;
    background-color: #9A8776;
  }
  
  .btn_filled {
    color: #fff !important;
    background-color: #9A8776 !important;
  }
  .btn_filled:hover {
    text-decoration: underline;
  }
  
  .secondry_title {
    font: 500 12px/20px Spartan;
    color: #3F4544;
    margin-bottom: 12px !important;
  }
  
  .management_header_logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #fff;
  }
  .management_header_logo .management_info_img img {
    margin-left: 10px;
    height: 32px;
    width: 32px;
    box-shadow: 0px 5px 15px #0000001a;
    border-radius: 50%;
  }
  
  .management_sidebar .list_group {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .management_sidebar .list_group_item {
    border: none !important;
    padding: 8px 0px;
    font: 500 14px Spartan;
    color: #2D2424;
    margin-top: 15px;
  }
  .management_sidebar .list_group_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .list_group_item img {
    height: 16px;
    width: 16px;
    margin: 0px 25px;
  }
  .management_sidebar .list_group_item.active {
    background-color: transparent;
  }
  .management_sidebar .list_group_item.active img {
    box-sizing: content-box;
    padding: 10px;
    background-color: #f6efe7;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .management_sidebar .list_group_sub_item {
    padding-left: 90px !important;
    border: none !important;
    padding-top: 8px;
    padding-bottom: 8px;
    font: 500 14px Spartan;
  }
  .management_sidebar .list_group_sub_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .admin_sidebar_footer {
    margin: 0px 25px;
  }
  .management_sidebar .admin_sidebar_footer h4 {
    font: 700 12px/30px Spartan;
    color: #3F4544;
    margin-bottom: 0px !important;
  }
  .management_sidebar .admin_sidebar_footer span {
    font: 500 8px/14px Spartan;
    color: #3F4544;
    display: block;
  }
  
  .user_admin {
    border: 1px solid #CDD0E34B;
    border-radius: 15px;
    background-color: #fff;
    padding: 40px;
  }
  .user_admin .user_heading {
    font: 500 14px/20px Spartan;
    color: #3F4544;
  }
  .user_admin .user_admin_detail {
    padding-bottom: 20px;
    border-bottom: 1px solid #7070702b;
    margin-bottom: 30px;
  }
  .user_admin .user_admin_detail .user_admin_img {
    position: relative;
  }
  .user_admin .user_admin_detail .user_admin_img .user_img {
    height: 127px;
    width: 127px;
  }
  .user_admin .user_admin_detail .user_admin_img .user_edit_img {
    height: 34px;
    width: 34px;
    position: absolute;
    bottom: 0px;
    right: 10px;
  }
  .user_admin .user_admin_detail .user_details_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user_admin .user_admin_detail .user_details_heading span {
    color: #9A8776;
    font-size: 14px;
  }
  .user_admin .user_admin_detail .label_title {
    font: 500 12px/14px Spartan;
    color: #3F4544;
  }
  .user_admin .user_admin_detail .form_control {
    font: 500 14px/16px Spartan;
    letter-spacing: 0px;
    color: #3F4544;
    background-color: transparent;
  }
  .user_admin .user_admin_detail .form_text {
    font: 500 10px/14px Spartan;
    color: #FF7575 !important;
    text-align: right;
  }
  .user_admin .user_admin_detail input::-moz-placeholder {
    color: rgba(63, 69, 68, 0.5);
  }
  .user_admin .user_admin_detail input:-ms-input-placeholder {
    color: rgba(63, 69, 68, 0.5);
  }
  .user_admin .user_admin_detail input::placeholder {
    color: rgba(63, 69, 68, 0.5);
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .user_admin .user_admin_detail .input_mobile_number {
    position: relative;
  }
  .user_admin .user_admin_detail .input_mobile_number span {
    font: 400 12px/16px Poppins;
    letter-spacing: 0px;
    color: #3F4544;
    position: absolute;
    top: 42px;
    left: 20px;
    border-right: 2px solid #707070;
    padding-right: 7px;
  }
  .user_admin .user_admin_detail .input_mobile_number .form_control {
    padding-left: 15px !important;
  }
  .adduser_input_countycode{
        position: relative;
      }
      .adduser_input_countycode span{
        position:absolute;
        top: 5px;
        left: 10px;
        color: #7d8180;
      }
      .adduser_input_countycode .form_control{
        padding-left: 45px;
       line-height: 40px!important;
      }
  .user_admin .user_permission .uesr_permission_heading {
    margin-bottom: 40px;
  }
  .user_admin .user_permission .uesr_permission_heading .user_heading {
    display: inline-block;
    margin-right: 15px;
  }
  .user_admin .user_permission .uesr_permission_heading .form_control {
    display: inline-block !important;
    width: auto !important;
    background-color: #F0F0F0 !important;
    /* color: #9A8776 !important; */
    border-radius: 7px;
  }
  
  .management_contents {
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-top-left-radius: 40px;
    padding: 50px;
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
  .management_contents .management_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #E8E8E8;
  }
  .management_contents .management_header h1 {
    font: 600 24px/33px Spartan;
    color: #3F4544;
  }
  .management_contents .management_header h1 img {
    margin-right: 20px;
  }
  .management_contents .management_header .form_control {
    border: 1px solid #CDD0E34B;
    border-radius: 46px;
    display: inline;
    width: auto;
    font-weight: 600 !important;
    font-size: 12px;
    color: #2D2424;
    padding: 5px 15px;
    height: auto;
  }
  .management_contents .management_contents_upload span {
    display: inline-block;
    font: 600 12px Spartan;
    color: #9A8776;
    border: 1px solid #9A8776;
    border-radius: 46px;
    padding: 10px 20px;
    margin-left: 15px;
    cursor: pointer;
  }
  .management_contents .management_contents_upload span:last-child {
    color: #fff;
    background-color: #9A8776;
  }
  .management_contents .management_contents_upload span:last-child:hover {
    text-decoration: underline;
  }
  .management_contents .management_details h4 {
    font: 600 16px/20px Spartan;
    color: #2E2E2E;
    margin-bottom: 12px;
  }
  .management_contents .management_details .management_details_contents {
    margin-bottom: 25px;
  }
  .management_contents .management_details .management_details_contents .management_details_contents_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .management_contents .management_details .management_details_contents .management_details_contents_header a {
    font: 500 12px/20px Spartan;
    color: #4377BB;
  }
  .management_contents .management_details .management_details_contents .management_details_form {
    border: 1px solid #CDD0E34B;
    border-radius: 15px;
    background-color: #fff;
    padding: 20px;
  }
  .management_contents .management_details .management_details_contents .form_control {
    border: 1px solid #CDD0E3;
    border-radius: 10px;
    font: 500 14px/20px Spartan;
  }
  .management_contents .management_details .management_details_contents label {
    font: 500 12px/20px Spartan;
    color: #2E2E2E;
  }
  .management_contents .management_details .management_details_contents .btn:focus {
    outline: none !important;
  }
  .management_contents .management_details .management_details_contents input::-moz-placeholder {
    color: #B9B9B9;
  }
  .management_contents .management_details .management_details_contents input:-ms-input-placeholder {
    color: #B9B9B9;
  }
  .management_contents .management_details .management_details_contents input::placeholder {
    color: #B9B9B9;
  }
  
  .text_red {
    color: #EA563F !important;
  }
  
  .mb_35 {
    margin-bottom: 35px;
  }

  .mb_15{
    margin-bottom: 15px;
  }

  .ml_25{
    margin-left: 25px !important;
  }

  .crud{
    margin-left: 50px;
    margin-top: 10px;
  }
  
  /* Bootstrap Checkbox Customization */
  .custom_checkbox {
    line-height: 25px !important;
  }
  
  .custom_checkbox .custom_control_input:checked ~ .custom_control_label::before {
    background-color: #9A8776;
    box-shadow: 0 0 3px #9A8776;
    width: 20px;
    height: 20px;
  }
  
  .custom_checkbox .custom_control_input ~ .custom_control_label::before {
    width: 20px;
    height: 20px;
    box-shadow: 0 0 3px #3F4544 !important;
  }
  
  .custom_checkbox .custom_control_input:checked ~ .custom_control_label::after {
    top: 2px !important;
    left: -26px !important;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer !important;
  }
  
  .custom_checkbox .custom_control_input:focus ~ .custom_control_label::before {
    box-shadow: 0 0 3px #9A8776;
  }
  
  .custom_checkbox .custom_control_input:active ~ .custom_control_label::before {
    box-shadow: 0 0 3px #9A8776;
    background: rgba(154, 135, 118, 0.9);
  }
  
  .custom_checkbox .custom_control_label::before {
    border-radius: 0.25rem;
  }
  
  .custom_control_label::before {
    background-color: #fff;
  }
  
  .custom_control_label span {
    padding-left: 0px;
    display: inline-block;
    font: 500 14px/20px Spartan;
    color: #3F4544;
  }
  .eyeIcon{
    cursor: pointer;
  }

  .add_user_img .upload_img{
    position: relative;
  }

  .add_user_img .profile_img{
    height: 130px;
    width: 130px;
    object-fit: contain;
    border-radius: 50%;
  }

  .add_user_img .camera_img{
    position: absolute;
    bottom: 25px;
    right: 30px;
  }
  /* Bootstrap Checkbox Customization End*//*# sourceMappingURL=index.css.map */
  