.admin_management {
    outline: none !important;
  }
  .admin_management .row_margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .admin_management .row_margin .col-lg-2,
  .admin_management .row_margin .col-lg-10 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .form_control:focus {
    box-shadow: none !important;
    border-color: #CDD0E34B !important;
  }
  
  .management_header_logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #fff;
  }
  .management_header_logo .management_info_img img {
    margin-left: 10px;
    height: 32px;
    width: 32px;
    box-shadow: 0px 5px 15px #0000001a;
    border-radius: 50%;
  }
  
  .management_sidebar .list_group {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .management_sidebar .list_group_item {
    border: none !important;
    padding: 8px 0px;
    font: 500 14px Spartan;
    color: #2D2424;
    margin-top: 15px;
  }
  .management_sidebar .list_group_item.active {
    background-color: transparent;
  }
  .management_sidebar .list_group_item.active img {
    box-sizing: content-box;
    padding: 10px;
    background-color: #f6efe7;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .management_sidebar .list_group_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .list_group_item img {
    height: 16px;
    width: 16px;
    margin: 0px 25px;
  }
  .management_sidebar .list_group_sub_item {
    padding-left: 90px !important;
    border: none !important;
    padding-top: 8px;
    padding-bottom: 8px;
    font: 500 14px Spartan;
  }
  .management_sidebar .list_group_sub_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .admin_sidebar_footer {
    margin: 0px 25px;
  }
  .management_sidebar .admin_sidebar_footer h4 {
    font: 700 12px/30px Spartan;
    color: #3F4544;
    margin-bottom: 0px !important;
  }
  .management_sidebar .admin_sidebar_footer span {
    font: 500 8px/14px Spartan;
    color: #3F4544;
    display: block;
  }
  
  .management_contents {
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-top-left-radius: 40px;
    padding: 50px;
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
  .management_contents .management_header {
    margin-bottom: 25px;
  }
  .management_contents .management_header h1 {
    font: 600 24px/33px Spartan;
    color: #3F4544;
  }
  .management_contents .management_details .management_details_contents .management_details_form {
    border: 1px solid #CDD0E34B;
    border-radius: 15px;
    background-color: #fff;
    padding: 30px;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card {
    border: 1px solid #EFEFEF;
    border-radius: 13px;
    padding: 25px;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card.active {
    background-color: #F9F9F9;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card img {
    border-radius: 14px;
    height: 50px;
    width: 50px;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card .media_body {
    font: 500 10px/18px Spartan;
    color: #3F4544;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card .media_body h5 {
    font: 600 13px/30px Spartan;
    color: #3F4544;
  }/*# sourceMappingURL=index.css.map */