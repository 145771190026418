.management_contents {
  background: transparent linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-top-left-radius: 40px;
  padding: 30px;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}

.transparent_button {
  background-color:transparent;
    border: none;
}

.form.management_contents {
  padding: 50px;
}

.background_transparent{
  background: transparent !important;
  border: none !important;
}

select:focus-visible {
  outline: none !important;
}

/* Toggle Button */
.onoffswitch_profile {
  position: relative;
  width: 90px;
  margin-right: 30px;
}

.onoffswitch_profile.profile_toggle {
  width: 48px;
}

.onoffswitch_profile.profile_toggle .onoffswitch_profile_inner:before {
  content: "" !important;
}

.onoffswitch_profile.profile_toggle .onoffswitch_profile_inner:after {
  content: "" !important;
  color: #fff !important;
}

.onoffswitch_profile.profile_toggle .onoffswitch_profile_switch {
  right: 27px !important;
}

/* .onoffswitch_profile.enable_disable .onoffswitch_profile_inner:before {
  content: "Enable" !important;
  color: #b9b9b9;
}

.onoffswitch_profile.enable_disable .onoffswitch_profile_inner:after {
  content: "Disable" !important;
  color: #b9b9b9;
} */

/* .onoffswitch_profile.enable_disable .onoffswitch_profile_label {
  border: 1px solid #b9b9b9;
}

.onoffswitch_profile.enable_disable .onoffswitch_profile_switch {
  background: #b9b9b9;
  border: 1px solid #b9b9b9;
} */

.onoffswitch_profile_checkbox {
  display: none;
}

.onoffswitch_profile_label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #b9b9b9;
  border-radius: 16px;
  margin-bottom: 0 !important;
}

.onoffswitch_profile_inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch_profile_inner:before,
.onoffswitch_profile_inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  font: 500 12px/34px Spartan;
  box-sizing: border-box;
}

.onoffswitch_profile_inner:before {
  content: "Active";
  padding-left: 10px;
  background-color: #21BF73 !important;
  color: #fff !important;
}

.onoffswitch_profile_inner:after {
  content: "Inactive";
  padding-right: 10px;
  background-color: #b9b9b9 !important;
  color: #fff !important;
  text-align: right;
}

.onoffswitch_profile_switch {
  display: block;
  width: 18px;
  /* margin: auto; */
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 65px;
  transition: all 0.3s ease-in 0s;
  height: 18px;
}

.onoffswitch_profile_checkbox:checked+.onoffswitch_profile_label {
  border: 1px solid #21BF73;
}

.onoffswitch_profile_checkbox:checked+.onoffswitch_profile_label .onoffswitch_profile_switch {
  background: #fff;
  border: 1px solid #fff;
}

.onoffswitch_profile_checkbox:checked+.onoffswitch_profile_label .onoffswitch_profile_inner {
  margin-left: 0;
}

.onoffswitch_profile_checkbox:checked+.onoffswitch_profile_label .onoffswitch_profile_switch {
  right: 2px !important;
}

/* Toggle button end */

/* Secondry Title */
.secondry_title {
  font: 500 12px/20px Spartan;
  color: #2e2e2e;
}
.main_secondry_title{
  text-align: left;
  font: 600 16px/20px Spartan !important;
  letter-spacing: 0px;
  color: #2E2E2E;
  opacity: 1;
}
.medium_title {
  font: 600 16px/20px Spartan;
  color: #2e2e2e;
  margin-bottom: 12px;
}

/* Secondry Title end */

/* Form Control */
.form_control {
  border: 1px solid #cdd0e3 !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  font-family: "Spartan", inherit !important;
  background-color: #ffffff;
}

.management_contents_search_bar .form_control::placeholder {
  font-size: 12px;
}

.form_control::placeholder {
  font-size: 12px;
  color: #b9b9b9;
}

.management_contents .management_details .management_details_contents label {
  font: 500 12px/20px Spartan;
  color: #2E2E2E;
}

.form_control:focus {
  box-shadow: none !important;
  border-color: #cdd0e34b !important;
}

/* Form control end */

/* Button  */
.btn {
  border: 1px solid #cdd0e34b !important;
  border-radius: 46px !important;
  font: 600 12px Spartan !important;
  padding: 8px 25px !important;
  color: #9a8776 !important;
  cursor: pointer;
}

.btn_ordr {
  border: 1px solid #cdd0e34b !important;
  border-radius: 46px !important;
  font: 600 12px Spartan !important;
  padding: 8px 25px !important;
  color: #9a8776 !important;
  cursor: pointer;
  display: none;
}

.sidebarButton {
  border: 1px solid #cdd0e34b !important;
  border-radius: 8px !important;
  font: 600 12px Spartan !important;
  padding: 7px 14px !important;
  color: #9a8776 !important;
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 5px;
}

.taxButton{
  box-shadow: 0px 15px 30px #9a877633;
  border-radius: 6px 6px 0px 0px;
  color: #A39486;
  font-size: 13px;
  background: #9A877633 0% 0% no-repeat padding-box;
  padding: 8px 16px;
  margin-right: 5px;
  border: none !important;
}

.taxButton_active{
  background-color: #A39486;
  color: #fff;
}

.sidebarButton_active {
  color: #fff !important;
  background-color: #9a8776 !important;
}

.btn:disabled {
  opacity: 0.5 !important;
  cursor: no-drop !important;
}

.btn_ordr.btn_primary {
  color: #fff !important;
  background-color: #9a8776 !important;
}

.btn.btn_primary {
  color: #fff !important;
  background-color: #9a8776 !important;
}

.btn:focus {
  box-shadow: none !important;
}

/* Button End */

.management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e8e8e8;
}
.management_header_tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 20px; */
  margin-bottom: 25px;
  border-bottom: 1px solid #e8e8e8;
}
.management_header_heading {
  font: 600 24px/53px Spartan !important;
  color: #3f4544 !important;
  margin-bottom: 0 !important;
}

.management_header_heading_tr {
  font: 600 24px/53px Spartan !important;
  color: #3f4544 !important;
  margin-right: 750px;
}

.management_contents h1 img {
  margin-right: 5px;
}

.management_header .form_control {
  border: 1px solid #cdd0e34b !important;
  border-radius: 46px !important;
  display: inline !important;
  width: auto !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #2d2424 !important;
  padding: 5px 15px !important;
  height: auto !important;
  margin-left: 10px !important;
  font-family: "Spartan", inherit !important;
}

.management_header_addtax {
  justify-content: left;
  padding-bottom: 0px;
  margin-bottom: 10px;
  border-bottom: none;
}

.addtax_btn {
  border: 1px solid #A39486;
  margin-right: 20px;
  cursor: pointer !important;
  font: normal normal 500 14px/20px Spartan;
  color: #A39486;
  background-color: transparent;
  border-radius: 7px !important;
  border-color: #9a8776;
  padding: 10px 10px 8px 10px;
  cursor: pointer;
}

.addtax_btn span {
  font: 500 13px/20px Spartan;
  color: #A39486;
}

.addtax_btn:hover {
  background-color: transparent;
  text-decoration: none;
}

.addtax_btn:hover a {
  text-decoration: none;
}

.management_header_tax_heading {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}
.no_border_bottom{
  border-bottom:none;
}
/* Search Bar */
.management_contents_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.management_contents_search_bar {
  max-width: 550px;
  width: 100%;
  position: relative;
}

.management_contents_search_bar>.form_control {
  border: 1px solid #cdd0e34b;
  border-radius: 7px !important;
  padding-left: 35px;
  font: 500 10px/10px Spartan;
  height: calc(2.25rem + 2px) !important;
  color: #6d6d6d;
}

.management_contents_search_bar img {
  position: absolute;
  top: 10px;
  left: 12px;
  z-index: 999;
}
.left_margin_0 .management_contents_upload span:last-child{
   margin-left: 0px !important;
  }
 .communication_tabs_btn button{
    margin-right: 8px;
    border-radius: 6px 6px 0 0 !important;
 }
 .tabbarButton_active{
  color: #9a8776!important;
  background: transparent!important;
 }
/* Search bar end */

.management_contents_upload span {
  display: inline-block;
  font: 600 12px Spartan;
  color: #9a8776;
  border: 1px solid #9a8776;
  border-radius: 46px;
  padding: 10px 20px;
  margin-left: 15px;
}

.management_contents_upload span img {
  vertical-align: bottom !important;
  margin-right: 5px;
}

.management_contents_upload span:last-child {
  background-color: #9a8776;
  color: #fff;
  margin-left: 25px !important;
}

/* Table */
.management_table table {
  border-collapse: separate;
  border-spacing: 0px 15px;
}

.management_table table th {
  font: 400 12px/20px Spartan;
  color: #2d2424;
  white-space: nowrap;
}

.management_contents .management_table table tbody tr {
  background-color: #fff;
}

.management_table table tbody td {
  font: 600 10px/20px Spartan;
  color: #2d2424;
  vertical-align: middle !important;
  white-space: nowrap;
}

.management_table table tbody td .admin_table_img {
  height: 50px;
  width: 50px;
  padding: 6px;
  box-sizing: content-box;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.management_table table tbody td .admin_table_img.admin_table_avatar {
  height: 50px;
  width: 50px;
  padding: 0px;
  border: none;
  box-sizing: content-box;
  border-radius: 50%;
}

.management_table table tbody td .admin_table_edit_img {
  height: 22px;
  width: 22px;
}

.management_table table tbody td:first-child {
  border-radius: 15px 0 0 15px;
}

.management_table table tbody td:last-child {
  border-radius: 0 15px 15px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}

.management_details_contents {
  margin-bottom: 25px;
  width: 100%;
}

.management_details_contents .row {
  width: 100%;
}

/* Table End */

/* Table Footer */
.table_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_footer .table_pages .form_control {
  width: auto !important;
  display: inline-block;
  border: 1px solid #cbcbcb !important;
  border-radius: 6px !important;
  padding: 7px 8px 4px !important;
  background-color: transparent !important;
  height: auto !important;
}

.table_footer .table_pages span {
  font: 500 12px/20px Spartan;
  color: #2d2424;
  margin-left: 15px;
}

.table_footer .pagination .page_item .page_link {
  font: 500 12px/20px Spartan !important;
  padding: 5px 10px !important;
  color: #2e2e2e !important;
  background-color: transparent !important;
  border: none !important;
  margin: 5px !important;
}

.table_footer .pagination .page_item .page_link:hover,
.table_footer .pagination .page_item .page_link:active,
.table_footer .pagination .page_item .page_link_active {
  background-color: #9a8776 !important;
  color: #fff !important;
  border: 1px solid #cdd0e34b;
  border-radius: 6px;
}

.table_footer .pagination .page_item .page_link:focus {
  outline: none !important;
}

/* Table Footer */

/* Text Color */
.text_red {
  color: #ea563f !important;
}

/* text color End */
.management_contents_main{
  margin-bottom: 20px;
}
.checkbox_display{
  text-align: left;
  font: normal normal 600 12px/20px Spartan;
  letter-spacing: 0px;
  color: #3F4544;
  opacity: 1;
}
.management_details_form {
  /* border: 1px solid #cdd0e34b; */
  border-radius: 15px;
  background-color: #fff;
  padding: 10px 20px;
  margin-left: 15px;
}

.tabCollection {
  display: flex;
}

.tabBtn {
  padding: 8px 12px;
  width: 120px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #9A877633;
  text-align: center;
  color: #9a8776;
  margin: 10px 5px;
}

.notificationTable {
  padding: 15px;
}

.notificationTable.profileNotificationTable table tr td:not(:first-child) {
  padding-left: 0px !important;
}

.notificationTable table {
  width: 100%;
}

.notificationTable table tr {
  width: 100%;
}

.notificationTable table tr th {
  font: 600 14px/40px Spartan;
  color: #3F4544;
}

.notificationTable table tr td {
  padding-left: 30px;
  padding-top: 10px;
  font: 600 14px/40px Spartan;
  color: #3F4544;
}

.tableSubHead {
  margin-top: 10px;
  color: #3F4544 !important;
  font-weight: 400 !important;
}

.activeTab {
  background-color: #9a8776 !important;
  color: #fff !important;
}

.tabBtn:hover {
  background-color: #9a8776;
  color: #fff;
}

.passwordTypes {
  width: 100%;
  padding: 40px 20px;
}

.passpoints {
  color: #929292;
  padding-top: 4px;
}

/* Bootstrap radio Customization */
.custom-radio .custom-control-label::before {
  background-color: #fff !important;
  border: 1px solid #9a8776;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #9a8776 !important;
  border: 1px solid #9a8776 !important;
  box-shadow: none !important;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #9a8776 !important;
}

/* Bootstrap radio Customization end*/

/* Bootstrap Checkbox Customization */
.custom_checkbox {
  line-height: 25px !important;
}

.custom_checkbox .custom_control_input:checked~.custom_control_label::before {
  background-color: #9a8776 !important;
  box-shadow: 0 0 3px #9a8776;
  width: 17px;
  height: 17px;
  border-color: #9a8776 !important;
}

.custom_checkbox .custom_control_input~.custom_control_label::before {
  width: 17px;
  height: 17px;
  box-shadow: 0 0 3px #3f4544 !important;
}

.custom_checkbox .custom_control_input:checked~.custom_control_label::after {
  top: 2px !important;
  left: -26px !important;
  width: 22px !important;
  height: 22px !important;
}

.custom_checkbox .custom_control_input:focus~.custom_control_label::before {
  box-shadow: 0 0 3px #9a8776 !important;
}

.custom_checkbox .custom_control_input:focus {
  box-shadow: 0 0 3px #9a8776 !important;
  border-color: #9a8776 !important;
}

.custom_checkbox .custom_control_input:active~.custom_control_label::before {
  box-shadow: 0 0 3px #9a8776 !important;
  background: rgba(154, 135, 118, 0.9);
}

.custom_checkbox .custom_control_label::before {
  border-radius: 0.25rem;
}

.custom_control_label::before {
  background-color: #fff;
}

.custom_control_label span {
  padding-left: 5px;
  padding-top: 4px;
  display: inline-block;
}

/* Bootstrap Checkbox Customization End*/

.onoffswitch {
  position: relative;
  width: 90px;
  margin-left: 10px;
}

.onoffswitch.yes_no {
  width: 60px;
  display: inline-block;
  vertical-align: middle;
}

.onoffswitch.yes_no .onoffswitch_inner:before {
  content: "Yes" !important;
}

.onoffswitch.yes_no .onoffswitch_inner:after {
  content: "No" !important;
}

.onoffswitch.yes_no .onoffswitch_switch {
  right: 40px !important;
}

.onoffswitch_checkbox {
  display: none;
}

.onoffswitch_label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #b9b9b9;
  border-radius: 16px;
  margin-bottom: 0 !important;
}

.onoffswitch_inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch_inner:before,
.onoffswitch_inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  font: 500 12px/24px Spartan;
  box-sizing: border-box;
}

.onoffswitch_inner:before {
  content: "Active";
  padding-left: 10px;
  background-color: transparent !important;
  color: #b9b9b9;
}

.onoffswitch_inner:after {
  content: "Inactive";
  padding-right: 10px;
  background-color: transparent !important;
  /* color: #2E8E1D; */
  color: #b9b9b9;
  text-align: right;
}

.onoffswitch_switch {
  display: block;
  width: 15px;
  /* margin: auto; */
  background: #b9b9b9;
  border: 1px solid #b9b9b9;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  bottom: 0;
  right: 65px;
  transition: all 0.3s ease-in 0s;
  height: 15px;
}

.onoffswitch_checkbox:checked {
  background: #2e8e1d;
  border: 1px solid #2e8e1d;
}

.onoffswitch_checkbox:checked+.onoffswitch_label {
  border: 1px solid #2e8e1d;
}

.onoffswitch_checkbox:checked+.onoffswitch_label .onoffswitch_switch {
  background: #2e8e1d;
  border: 1px solid #2e8e1d;
}

.onoffswitch_checkbox:checked+.onoffswitch_label .onoffswitch_inner:before,
.onoffswitch_checkbox:checked+.onoffswitch_label .onoffswitch_inner:after {
  color: #2e8e1d;
}

.onoffswitch_checkbox:checked+.onoffswitch_label .onoffswitch_inner {
  margin-left: 0;
}

.onoffswitch_checkbox:checked+.onoffswitch_label .onoffswitch_switch {
  right: 5px !important;
}

.popup_justify {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.dispNone {
  display: none;
}

.management_contents .management_table_product .media {
  display: inline-flex !important;
  border: 1px solid #CDD0E3;
  border-radius: 10px;
  padding: 5px 20px 5px 5px;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.management_contents .management_table_product .media h5 {
  font: 600 10px/20px Spartan;
  color: #2D2424;
  margin-bottom: 0px;
}

.management_contents .management_table_product .media p {
  font: 400 10px/20px Spartan;
  color: #2D2424;
  margin-bottom: 0px;
}

.management_contents .management_table_product .media img {
  height: 60px;
  width: 60px;
}

.management_contents .management_table_product .media .close_icon {
  height: 12px;
  width: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.decor_banner_img {
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.imagemarkerWidth {
  width: 75%;
}

.equalHeight {
  min-height: 117px;
  height: 100%;
}

.parentCheckbox {
  position: relative;
  top: 47px;
  left: 24px;
  cursor: pointer;
  z-index: 9;
}
.childCheckbox{
  background: white;
  top: 2px;
  position: relative;
  cursor: pointer;
  z-index: 9;
  left: 14px;
}
.add_parentCheckbox {
  position: relative;
  top: 35px;
  left: 513px;
  cursor: pointer;
  z-index: 9;
}

.edit_parentCheckbox {
  position: relative;
  top: 35px;
  left: 650px;
  cursor: pointer;
  z-index: 9;
}

.view_parentCheckbox {
  position: relative;
  top: 35px;
  left: 780px;
  cursor: pointer;
  z-index: 9;
}

.table-width {
  min-height: 60% !important;
}

.search_list .search_title {
  font: 700 13px/16px Spartan;
  color: black;
}

.search_list .search_card {
  display: flex;
  align-items: center;
  background: #F6F6FA;
  border-radius: 11px;
  padding: 15px;
  margin-bottom: 15px;
}

.search_list .search_card img {
  height: 50px;
  width: 50px;
  padding: 5px;
  background-color: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  margin-right: 10px;
}

.search_list .search_card .card_contents h3 {
  font: 600 10px/16px Spartan;
  color: #2D2424;
}

.search_list .search_card .card_contents p {
  font: 500 9px/11px Spartan;
  color: #2D2424;
  margin-bottom: 0px;
}
.management_contents_upload span.outline_btn:last-child{
  background: transparent;
  margin-left: 0px!important;
  color: #9a8776;
  border-radius: 7px;
  cursor: pointer;
}

.management_contents_upload span.outline_btn {
  background: transparent;
  margin-left: 0px!important;
  color: #9a8776;
  border-radius: 7px;
  cursor: pointer;
  margin-right: 10px !important;
}
.outline_btn{
  background: transparent;
  color: #9a8776;
  margin-bottom: 20px;
  border-radius: 7px;
 
}
.banner_img_box_outline{
  border: 1px solid #CDD0E3;
border-radius: 15px;
min-height: 185px;
}
.banner_img_box_outline1{
  border: none;
border-radius: 15px;
min-height: 185px;
}
.banner_img_box_outline .upload_img{
  border: 1px dashed #CDD0E3!important;
  border-radius: 10px;
  padding: 15px;
}
.banner_img_box_outline .upload_img img{
  width: 54px;
height: 36px;
margin-bottom: 10px;
}
.banner_img_box_outline .upload_img p{
  width: 82px;
height: 29px;
text-align: center;
font: normal normal 500 10px/17px Spartan;
letter-spacing: 0px;
color: #B9B9B9;
margin: auto;
}
.banner_img_preview{
  position: relative;
  position: relative;
  width: 95px;
  display: inline-block;

}
.banner_img_preview img.previewImgs{
padding: 8px;
width: 75px;
height: 77px;
border: 1px solid #CDD0E3;
margin: 20px 15px 0 20px;
border-radius: 7px;
object-fit: cover;
position: relative;
}
.previewImgsdeleteicon{
  position: absolute;
  top: 10px;
    right: -10px;
  cursor: pointer;
}
.outline_left_btn .management_contents_upload span{
  background: transparent!important;
  border: 1px solid #A39486;
border-radius: 5px;
margin-left: 0px!important; 
font: normal normal 500 13px/20px Spartan;
color: #A39486;
cursor: pointer;
}
/*Antd modal */
.ant-modal {
  max-width: unset;
  margin: unset;
  max-height: unset;
}

.ant-modal-centered::before {
  content: unset;
}
.form_control.form_control_select_report{
border-radius: 5px !important;
padding: 7px 20px;
margin-right: 15px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 10px 20px #0000000D;
border: 1px solid #CDD0E34B;
}
.reports_card_box{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000000D;
  border: 1px solid #CDD0E34B;
  border-radius: 24px;
  padding: 30px;
  min-height: 310px;
}
.salesreport_card{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #0000000D;
  border: 1px solid #CDD0E34B;
  border-radius: 24px;
  padding: 30px;
  min-height: 360px;
}
.salesreport_card h4{
font-size: 20px;
margin-bottom: 30px;
}
.reports_card_box h4{
  font: normal normal 600 14px/30px Spartan;
}
.reports_card_listed p{
  font: normal normal 500 12px/20px Spartan;
  color: #A39486;
  margin-bottom: 10px;
}
.reports_card_listed p:hover {
  text-decoration: underline !important;
}
/*Antd modal end */

.export_icon {
  display: inline-block;
  margin-left: 15px;
  height: 25px;
  width: 25px;
}

.export_icon_small{
  height: 15px;
  width: 15px;
  display: inline-block;
  margin-left: 5px;
}

.export_card_box {
  position: absolute;
  right: 3px;
  top: 53px;
}

.management_header_downloads_icon {
  margin-left: 10px;
  height: 25px;
  width: 25px;
}

.management_header_downloads_text_ex {
  margin-left: 10px;
  margin-right: 2px;
  font: 600 15px/18px Spartan;
  color: #2D2424;
}

.management_header_downloads_text_ex img{
  height: 15px;
  width: 15px;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 5px;
}

.management_contents_search_sale {
  justify-content: end;
  margin-bottom: 0px;
}
.filter_dropdown{
  border: none;
  border-radius: 5px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDD0E34B;
  padding: 7px 10px;
  font: 500 12px/18px Spartan;
}
.management_contents_search_sale .management_contents_search_bar {
  width: 100%;
  max-width: none;
}
.management_header .customer_add_btn span{
  margin-left: 0px!important;
  border-radius: 7px;
}
.export_icon_text{
  font: normal normal 600 10px/15px Spartan;
  color: #2D2424;
  margin-left: 10px;
}
.subheading_title h4{
  font: normal normal 600 16px/20px Spartan;
  letter-spacing: 0px;
  color: #2E2E2E;
}
/* Profile styles */

.profile_display {
  width: 100%;
  height: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 7px;
}

.profile_header {
  width: 100%;
}

.profile_header h4 {
  font-size: 20px;
  padding: 30px 0px 10px 30px;
}

.profile_details {
  width: 100%;
  margin: 0px;
}

.profile_image {
  justify-content: center;
  text-align: center;
}

.profile_image img {
  width: 80px;
  height: 80px;
  margin: 20px auto;
  border-radius: 40px;
}

.profile_detail {
  padding: 20px 0px;
}

.profile_password {
  width: 100%;
}
.profile_password_hr p{
  border-top: 1px solid #E2E5F2;
  margin-left: 15px;
}
.link {
  cursor: pointer;
  text-decoration: underline;
  font: normal normal medium 14px/20px Spartan;
  letter-spacing: 0px;
  color: #A39486;
  opacity: 1;
}

.list {
  padding: 10px 0px;
  font: normal normal 600 14px/20px Spartan;
}
.list span{
  font: normal normal normal 14px/20px Spartan;
letter-spacing: 0px;
color: #3F4544;
}
.query_message h4{
  font: normal normal 600 16px/20px Spartan;
  letter-spacing: 0px;
  color: #3F4544;
  opacity: 1;
}
.profile_detail {
  width: 100%;
  display: inline-block;
}

.management_contents_search_bar input {
  background: #F0F0F4;
}
.abandone_tab_btn button.sidebarButton{
  box-shadow: 0px 15px 30px #9A877633;
border-radius: 6px 6px 0px 0px!important;
padding: 10px 15px!important;
}
.abandoned_email label{
  display: flex!important;
}
.abandoned_email label{
  font: normal normal 600 14px/20px Spartan!important;
  color: #2D2424;
}
.abandoned_email_checkbox label{
  zoom: 1.3;
}
.abandoned_email .abandoned_email_checkbox span{
  font: normal normal 500 12px/16px Spartan!important;
  color: #9A9A9A;
}
.abandoned_email .abandoned_email_checkbox button{
  zoom: 1.3;
}
.abandoned_email .abandoned_email_checkbox button span{
  color:#fff
}
.display_block{
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
}
.abandoned_email p{
  font: normal normal 500 12px/16px Spartan;
  letter-spacing: 0px;
  color: #9A9A9A;
  margin-top: 5px;
  margin-bottom: 5px;
}
.abandone_placeholder{
  position: relative;
}
.abandone_placeholder p{
  position: absolute;
    right: 10px;
    top: 7px;
    font: normal normal 500 14px/16px Spartan;
letter-spacing: 0px;
color: #9A9A9A;
}
/* Customer details  */

.customer_detail {
  margin-bottom: 20px;
  display: flex;
}

.customer_detail_title {
  font: 500 14px/20px Spartan;
  color: #3F4544;
  min-width: 100px;
  display: inline-block;
}

.customer_detail_desc {
  font: 600 14px/20px Spartan;
  color: #3F4544;
  padding-left: 10px;
}

.border_top {
  border-top: 1px solid #E0E0E0;
  padding-top: 20px;
}

.loyalty_card {
  padding: 20px;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  margin: 0 15px 25px;
  max-height: 175px;
  overflow-y: auto;
}

.border_right:not(:last-child) {
  border-right: 1px solid #E0E0E0;
}

.loyalty_title {
  font: 500 14px/20px Spartan;
  color: #3F4544;
  padding: 10px 0 5px;
}

.loyalty_point {
  font: 600 18px/28px Spartan;
  color: #3F4544;
  padding-bottom: 5px;
}

.address_title {
  font: 500 14px/20px Spartan;
  color: #3F4544;
}

.address_details {
  font: 400 12px/20px Spartan;
  color: #3F4544;
}

.customer_rating_value {
  font: 600 36px/60px Spartan;
  color: #2E2E2E;
  margin-right: 30px;
}

.customer_rating_title {
  font: 600 12px/14px Spartan;
  color: #3D3D3D;
}

.customer_rating_accordion .accordion_card {
  width: 100%;
}

.customer_rating_accordion .accorion_row {
  text-align: left;
  margin-top: 20px;
}

.customer_rating_accordion .accordion_card_body .accorion_row {
  border-top: 1px solid #DEDEDE;
  padding-top: 20px;
}

.customer_rating_accordion .accordion_card_header {
  background-color: transparent !important;
  border: none !important;
  padding: 20px 10px;
}

.customer_rating_accordion .order_id {
  font: 600 16px/19px Spartan;
  color: #3D3D3D;
  display: inline-block;
  margin-right: 10px;
}

.customer_rating_accordion .order_date {
  font: 600 12px/14px Spartan;
  color: #737373;
}

.customer_rating_accordion .order_image {
  height: 100px;
  width: 100px;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  padding: 2px;
  object-fit: contain;
  margin-right: 30px;
}

.customer_rating_accordion .order_image_detail {
  display: flex;
}

.customer_rating_accordion .order_image_detail_name {
  font: 600 14px/17px Spartan;
  color: #3D3D3D;
}

.customer_rating_accordion .order_image_detail_title {
  font: 500 12px/20px Spartan;
  color: #737373;
  display: inline-block;
  margin-right: 5px;
  min-width: 90px;
}

.customer_rating_accordion .order_image_detail_desc {
  font: 600 12px/20px Spartan;
  color: #3D3D3D;
}

.customer_rating_accordion .order_image_detail_amount {
  font: bold 16px/20px Spartan;
  color: #9A8776;
  margin-top: 30px;
}

.customer_rating_accordion .order_address_title {
  font: 600 12px/14px Spartan;
  color: #3D3D3D;
  margin-bottom: 15px;
}

.customer_rating_accordion .order_address_detail span {
  font: 12px/20px Spartan;
  color: #3F4544;
  display: block;
}

.customer_rating_accordion .order_status_details {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: flex-end;
}

.customer_rating_accordion .order_status {
  min-width: 80px;
  font: 600 11px/14px Spartan;
  padding: 7px 14px;
  border-radius: 22px;
  text-align: center;
}


.customer_rating_accordion .order_status.order_status_pending {
  background-color: #FBEDC5;
  color: #c07025
}

.customer_rating_accordion .order_status.order_status_completed {
  color: #3f9948;
  background-color: rgba(91, 180, 100, 0.22);
}

.customer_rating_accordion .order_btn {
  border: 1px solid #9A8776;
  border-radius: 7px;
  font: 600 11px/14px Spartan;
  padding: 8px 16px 5px;
  color: #9A8776;
}

.accordion_rating_card {
  border: 1px solid #DEDEDE;
  border-radius: 12px;
  padding: 20px;
  margin: 20px 0 0;
}

.accordion_rating_card .accordion_rating_card_heading {
  font: 600 10px/14px Spartan;
  letter-spacing: 0px;
  color: #3F4544;
}

.accordion_rating_card .accordion_rating_card_title {
  font: 600 10px/14px Spartan;
  letter-spacing: 0px;
  color: #2E2E2E;
  display: flex;
  justify-content: space-between;
}

.accordion_rating_card .accordion_rating_card_des {
  font: 600 14px/30px Spartan;
  color: #2E2E2E;
}

/* Customer details End  */
/* user avatar */
.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
/* End user avatar */

.previewImgs {
  width: 15%;
  height: 60px;
}
.status_active{
  padding: 5px 10px;
    color: #2E8E1D;
    background: #D4F2CF;
  border-radius: 50px;
}

.status_inactive{
  padding: 5px 10px;
    color: #F64949;
    background: #FFDBE0;
    border-radius: 50px;
}
.status_pending {
  padding: 5px 10px;
  color: orange;
  background: #f8e7c6;
  border-radius: 50px;
}
.asterik{
  position: relative;
  top: 10px;
  font-size: 20px;
  margin-left: 2px;
}
.adduser_input_countycode{
  position: relative;
}
.adduser_input_countycode span{
  position:absolute;
  top: 7px;
  left: 10px;
  color: #7d8180;
}
.adduser_input_countycode_form{
  padding-left: 45px;
 line-height: 40px!important;
}
.banner_layout{
  position: relative;
  margin-top: 10px;
}
.banner_layout img{
  margin-left: 30px;
}
.select_input_name .ant-select-multiple .ant-select-selection-item{
  top: -7px;
}
.form_control.productlist_input{
  font-size: 12px !important;
}
.read_only_container {
  position: relative;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent overlay */
  pointer-events: none !important; /* Prevent interaction with underlying content */
}


.hide_sort_time_edit table th{
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none !important;
}

.description_ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}

