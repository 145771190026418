.admin_management {
    outline: none !important;
  }
  .admin_management .row_mlr_0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .admin_management .row_mlr_0 .col_plr_0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .form_control:focus {
    box-shadow: none !important;
    border-color: #CDD0E34B !important;
  }
  
  .grayish_orange {
    color: #9a8776 !important;
  }
  
  .management_header_logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #fff;
  }
  .management_header_logo .management_info_img img {
    margin-left: 10px;
    height: 32px;
    width: 32px;
    box-shadow: 0px 5px 15px #0000001a;
    border-radius: 50%;
  }
  
  .management_sidebar .list_group {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .management_sidebar .list_group_item {
    border: none !important;
    padding: 8px 0px;
    font: 500 14px Spartan;
    color: #2D2424;
    margin-top: 15px;
  }
  .management_sidebar .list_group_item.active {
    background-color: transparent;
  }
  .management_sidebar .list_group_item.active img {
    box-sizing: content-box;
    padding: 10px;
    background-color: #f6efe7;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .management_sidebar .list_group_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .list_group_item img {
    height: 16px;
    width: 16px;
    margin: 0px 25px;
  }
  .management_sidebar .list_group_sub_item {
    padding-left: 90px !important;
    border: none !important;
    padding-top: 8px;
    padding-bottom: 8px;
    font: 500 14px Spartan;
  }
  .management_sidebar .list_group_sub_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .admin_sidebar_footer {
    margin: 0px 25px;
  }
  .management_sidebar .admin_sidebar_footer h4 {
    font: 700 12px/30px Spartan;
    color: #3F4544;
    margin-bottom: 0px !important;
  }
  .management_sidebar .admin_sidebar_footer span {
    font: 500 8px/14px Spartan;
    color: #3F4544;
    display: block;
  }
  
  .management_contents {
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-top-left-radius: 40px;
    padding: 50px;
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
  .management_contents .management_header {
    margin-bottom: 25px;
  }
  .management_contents .management_header h1 {
    font: 600 24px/33px Spartan;
    color: #3F4544;
  }
  .management_contents .management_header h1 img {
    margin-right: 30px;
  }
  .management_contents .management_header h1 span {
    font: 500 12px/20px Spartan;
    color: #2D2424;
    margin-left: 10px;
    vertical-align: middle;
  }
  .management_contents .management_details .management_details_contents .border_green {
    border-color: #B6E1AF !important;
  }
  .management_contents .management_details .management_details_contents h4 {
    font: 600 16px/20px Spartan;
    color: #2E2E2E;
    margin-bottom: 12px;
  }
  .management_contents .management_details .management_details_contents .product_details {
    display: flex;
    justify-content: space-between;
  }
  .management_contents .management_details .management_details_contents .product_details h3 {
    font: 600 12px/20px Spartan;
    color: #3F4544;
  }
  .management_contents .management_details .management_details_contents .product_details img {
    height: 70px;
    width: 70px;
    padding: 5px;
    box-sizing: content-box;
    border: 1px solid #EBEBEB;
    border-radius: 5px;
  }
  .management_contents .management_details .management_details_contents .product_details span {
    font: 500 12px/24px Spartan;
    color: #2D2424;
  }
  .management_contents .management_details .management_details_contents .product_details .product_name {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
  }
  .management_contents .management_details .management_details_contents .product_details .product_name a {
    text-decoration: underline;
    font: 600 12px/20px Spartan;
    color: #9A8776;
    display: block;
  }
  .management_contents .management_details .management_details_contents .product_details .product_name .product_name_value span {
    line-height: 12px !important;
  }
  .management_contents .management_details .management_details_contents .product_details .product_name .product_name_value span:last-child {
    font-weight: 600 !important;
  }
  .management_contents .management_details .management_details_contents .product_details .product_value {
    min-height: 50px;
    display: inline-flex;
    align-items: center;
  }
  .management_contents .management_details .management_details_contents .management_details_form {
    border: 1px solid #CDD0E34B;
    border-radius: 15px;
    background-color: #fff;
    padding: 30px;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card.active {
    background-color: #F9F9F9;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card img {
    border-radius: 14px;
    height: 25px;
    width: 25px;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card .media_body {
    font: 500 12px/24px Spartan;
    color: #2D2424;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card .media_body h5 {
    font: 600 18px/19px Spartan;
    color: #2E8E1D;
  }
  .management_contents .management_details .management_details_contents .management_details_form .media_card .media_body a {
    color: #2E8E1D;
    text-decoration: underline;
  }
  .management_contents .management_details .management_details_contents .admin_payment {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
  .management_contents .management_details .management_details_contents .admin_payment span:first-child {
    font: 500 13px/15px Spartan;
    color: #3D3D3D;
  }
  .management_contents .management_details .management_details_contents .admin_payment span:last-child {
    font: normal normal 600 13px/15px Spartan;
    color: #3D3D3D;
  }
  .management_contents .management_details .management_details_contents .admin_payment:last-child {
    border-top: 1px solid #EEEEEE;
    padding-top: 15px;
    margin-bottom: 0px;
  }
  .management_contents .management_details .management_details_contents .admin_payment:last-child span:first-child {
    font: 700 17px/20px Spartan;
  }
  .management_contents .management_details .management_details_contents .admin_payment:last-child span:last-child {
    font: 600 16px/19px Spartan;
  }
  .management_contents .management_details .management_details_contents .admin_customer .admin_customer_details:not(:last-child) {
    border-bottom: 1px solid #D4D4D4;
    margin-bottom: 12px;
  }
  .management_contents .management_details .management_details_contents .admin_customer .admin_customer_details h3 {
    font: 500 12px/20px Spartan;
    color: #3F4544;
    margin-bottom: 12px;
  }
  .management_contents .management_details .management_details_contents .admin_customer .admin_customer_details span {
    font: 600 14px/20px Spartan;
    color: #3F4544;
    display: block;
  }
  
  /* Order Progressbar */
  .order_progress_bar {
    display: block;
    text-align: left;
    position: relative;
    z-index: 1;
  }
  .order_progress_bar .order_progress_bar_values {
    width: 100%;
  }
  .order_progress_bar .order_progress_bar_values .order_progress_bar_details {
    position: relative;
    display: flex;
    min-height: 120px;
    height: 100%;
  }
  .order_progress_bar .order_progress_bar_values .order_progress_bar_details:before {
    content: "";
    position: absolute;
    border-left: 1px dashed #9a8776;
    z-index: -1;
    height: 100%;
    left: 28px;
    top: 0px;
  }
  .order_progress_bar .order_progress_bar_values .order_progress_bar_details.rm-line:before {
    border: none !important;
  }
  .order_progress_bar .order_progress_bar_values .order_progress_bar_details.completed:before {
    border-left: 1px solid green !important;
  }
  .order_progress_bar .order_progress_bar_values img {
    height: 55px;
    width: 55px;
  }
  .order_progress_bar .order_progress_bar_values .order_progres_bar_status {
    font: 600 12px/14px Spartan;
    letter-spacing: 0px;
    color: #3D3D3D;
    padding-left: 20px;
    padding-top: 10px;
  }
  .order_progress_bar .order_progress_bar_values .order_progres_bar_status .order_progress_bar_date {
    font: 500 12px/20px Spartan;
    color: #737373;
  }
  .order_progress_bar .order_progress_bar_values .order_progres_bar_status .order_progress_bar_date span {
    display: block;
    margin-bottom: 5px;
  }/*# sourceMappingURL=index.css.map */